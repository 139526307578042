
import { useEffect, useState } from "react";

import { supabase } from "../../client";

// Functions 
import hslToHex from "../../functions/hslToHex";
import rng from "../../functions/rng";

//Components
import Form, { FormRow } from "../../components/form/Form";
import PeopleCard from "../../components/cards/PeopleCard";
import LargeTextInput from "../../components/form/LargeTextInput";
import LargeTextArea from "../../components/form/LargeTextArea";
import { SecondaryButton } from "../../components/Buttons";
import ColorSelect from "../../components/ColorSelect";


// Icons 
import { BsFillPersonFill } from "react-icons/bs";


function AddPeopleView({ onAdd = () => {} }) {

    const [loading, setLoading] = useState(false)

    const [name, setName] = useState("");

    const [invalid, setInvalid] = useState();
    const [invalidMessage, setInvalidMessage] = useState();


    const [errorMessage, setErrorMessage] = useState("");


    const [color, setColor] = useState(hslToHex(rng(1, 360), 67, 64));
    


    useEffect(() => {
        setName("");
    }, [])


    useEffect(() => {
        let invalid = false;
        let invalidMessage;
        if (name.length <= 0) invalid = true;
        if (name.length > 20) {
            invalid = true;
            invalidMessage = "Name must be between 1 & 20 characters."
        }
        setInvalid(invalid);
        setInvalidMessage(invalidMessage);
    }, [name])


    function handleNameChange(name) {
        setName(name);
    }





    async function insertPerson() {
        setLoading(true);
        const { data, error } = await supabase
            .from('p_people')
            .insert([
                { name: name, color: color },
            ])

            console.log(error)


        if (error) setErrorMessage("Error, try again!")
        if (!error) onAdd();

        setLoading(false);
    }


    return (
        <div className="felx flex-col">
            {name ? <PeopleCard {...{ name, color }} /> : ""}
            <div className="mt-4">
                <Form buttonText={"Add Person"} invalid={invalid} invalidMessage={invalidMessage} error={errorMessage} loading={loading} onSubmit={() => insertPerson()} disabled={loading}>
                    <FormRow>
                        <LargeTextInput placeholder={"Their name"} value={name} icon={<BsFillPersonFill size="20" />} onChange={handleNameChange} />
                    </FormRow>
                </Form>
                <div className="py-6"/>
                <ColorSelect currentColor={color} onChange={setColor}/>
            </div>
            <div className="h-1/2" />

        </div>
    );
}

export default AddPeopleView;