import React from 'react';

import { motion, AnimatePresence } from "framer-motion"


import { CgClose } from "react-icons/cg"

const validAnimation = {
    hidden: {
        y: "-7px",
        opacity: 0
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
        }
    },
    exit: {
        y: "-7px",
        opacity: 0
    }
}

function LargeTextInput({ icon, type = "text", placeholder, onChange, value, errorMessage, autoFocus = false }) {
    return (
        <div>
            <div className='px-3 py-2 rounded-xl text-base font-bold text-theme-color w-full bg-theme-color-02 flex flex-row justify-start items-center border-4 border-theme-color'>
                {icon &&
                    <div className='mr-4'>
                        {icon}
                    </div>}
                <input autoFocus={autoFocus} type={type} placeholder={placeholder} onChange={e => onChange(e?.target?.value || "")} value={value} className='w-full bg-transparent outline-none border-0 text-theme-mode-text placeholder-theme-color-05' />
                <AnimatePresence
                    initial={false}
                    exitBeforeEnter={true}>
                    {errorMessage && errorMessage?.length && errorMessage?.length > 0 &&
                        <motion.div
                            variants={validAnimation}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            className='absolute mt-16 text-sm font-semibold text-theme-color flex justify-start items-center' >
                            <div className='-ml-1 mr-1'>
                                <CgClose size="16" />
                            </div>
                            {errorMessage}
                        </motion.div>}
                </AnimatePresence>
            </div>
        </div>
    );
}

export default LargeTextInput;