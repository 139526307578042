

import { useState, useEffect, createContext, useMediaQuery } from "react";
import db from "./db.js"

import { supabase } from "./client.js";

import { getThemeData, themeModes } from "./config/themes.js";

import { motion, AnimatePresence, checkTargetForNewValues } from "framer-motion";

import SnowflakeId from './snowflake/uid';


// ICONS
import { IconContext } from "react-icons";
import { ImSearch } from "react-icons/im";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { BsFilePerson, BsFilePersonFill, BsFillFilePersonFill, BsFillLightningChargeFill, BsPeopleFill } from "react-icons/bs";
import { FaHeart, FaPray } from "react-icons/fa";

// ROUTER
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation
} from "react-router-dom";

// COMPONENTS
import TopBar from "./components/nav/TopBar.jsx";
import NavBar from "./components/nav/NavBar.jsx";
import NavBarIcon from "./components/nav/NavBarIcon.jsx";
import ProfilePicture from "./components/profile/ProfilePicture.jsx";
import PopUp from './components/PopUp.jsx';
import Prompt from "./components/Prompt.jsx"
import SubView from './components/SubView.jsx';
import PageContextProvider from './components/PageContextProvider';

// VIEWS
import GratitudeView from "./views/GratitudeView.jsx";
import PeopleView from "./views/PeopleView.jsx";
import PrayerView from "./views/PrayerView.jsx";
import ProfileView from "./views/ProfileView.jsx";
import LoginView from './views/LoginView';
import LoadingView from './views/LoadingView';
import ProfileSetupView from "./views/ProfileSetupView";

// SUB VIEWS
import SettingsView from "./views/sub/SettingsView.jsx";
import HelpView from "./views/sub/HelpView.jsx";
import SelectSessionView from "./views/sessions/SelectSessionView.jsx";
import PrivacyPolicy from "./views/PrivacyPolicy.jsx";
import FriendsView from "./views/friends/FriendsView.jsx";


var snowflake = new SnowflakeId({
  mid: 42,
  offset: (2019 - 1970) * 31536000 * 1000
});


const navBarAnimation = {
  hidden: {
    opacity: 0,
    y: "20vh"
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.12,
    }
  },
  exit: {
    opacity: 0,
    y: "20vh"
  }
}


const topBarAnimation = {
  hidden: {
    y: "-20vh"
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.28,
    }
  },
  exit: {
    y: "-20vh"
  }
}

const promptAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.1,
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.1,
    }
  }
}


function App() {

  //localStorage.removeItem('theme');
  let themeInStorage = localStorage.getItem('theme');
  themeInStorage = (themeInStorage ? JSON.parse(themeInStorage) : undefined);


  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [theme, setTheme] = useState(getThemeData(themeInStorage?.color, themeInStorage?.mode));
  const [basePath, setBasePath] = useState("/"); // always updates for nav bar
  const [prompt, setPrompt] = useState(null); // should get a object with .value (for inner text of prompt) and .onApprove (the function that is executed, when approved) and .buttonValues {approve: "Okay", cancel: "Cancel"}
  const [popUp, setPopUp] = useState(false); // should get .element (inner jsx elemnt) and .title (for optional title)
  const [showNavBar, setShowNavBar] = useState(true);
  const [loading, setLoading] = useState(true);




  const navigateTo = useNavigate();
  const location = useLocation();


  const navigate = (to, options) => {
    // Close popups and prompts
    setPopUp(null);
    setPrompt(false);

    navigateTo(to, options)
  }



  useEffect(() => {

    checkUser();
    window.addEventListener("hashchange", () => {
      checkUser();
    })


    setTimeout(() => fireNavBarCondition(), 300);
  }, [])



  useEffect(() => {

    if (user === null) return




    supabase
      .channel(`public:profiles:user_id=eq.'${user.id}'`)
      .on('postgres_changes', { event: '*', schema: 'public', table: 'profiles' }, payload => {
        getProfile();
      })
      .subscribe()






    async function getProfile() {
      if (user) {
        let { data: profiles, error } = await db.getProfile({ user_id: user.id });
        let profile = profiles?.[0];
        if (profile) setProfile(profile);
      } else setProfile(null)
    }
    getProfile();


  }, [user])


  async function getProfile() {
    if (user) {
      let { data: profiles, error } = await db.getProfile({ user_id: user.id });
      let profile = profiles?.[0];
      if (profile) setProfile(profile);
    } else setProfile(null)
  }

  useEffect(() => {
    // set theme for user if logged in in and theme is set in db
    if (profile) {
      const { color, mode } = profile?.p_theme || profile?.theme || {}
      const theme = getThemeData(color, mode)
      setTheme(theme);
      localStorage.setItem("theme", JSON.stringify({ color, mode }))
    }
  }, [profile])

  useEffect(() => {
    let basePath = location?.pathname?.split("/")[1];
    setBasePath(basePath);
    setPopUp(null);


    setTimeout(() => fireNavBarCondition(), 300);


  }, [location])






  useEffect(() => { // when popup is open then hide navbar
    if (popUp) setShowNavBar(false);
    else setShowNavBar(true);
  }, [popUp])


  useEffect(() => {


    if (theme) {

      const script = document.createElement('script');



      script.innerText = `
      
      function applyTheme() {
        var root = document.querySelector(':root');
        root.style.setProperty('--theme-color', "${theme.color.css}");
        root.style.setProperty('--theme-color-02', "rgba(${theme.color.r}, ${theme.color.g}, ${theme.color.b}, 0.2)");
        root.style.setProperty('--theme-color-05', "rgba(${theme.color.r}, ${theme.color.g}, ${theme.color.b}, 0.5)");
        root.style.setProperty('--theme-color-08', "rgba(${theme.color.r}, ${theme.color.g}, ${theme.color.b}, 0.8)");
        root.style.setProperty('--theme-color-09', "rgba(${theme.color.r}, ${theme.color.g}, ${theme.color.b}, 0.9)");
        root.style.setProperty('--theme-mode-background', "${theme.mode.background.css}");
        root.style.setProperty('--theme-mode-primary', "${theme.mode.primary.css}");
        root.style.setProperty('--theme-mode-secondary', "${theme.mode.secondary.css}");
        root.style.setProperty('--theme-mode-uicolor', "${theme.mode.uicolor.css}");
        root.style.setProperty('--theme-mode-text', "${theme.mode.text.css}");


        document.querySelector('#status-bar-color').setAttribute('content', "${theme.mode.background.hex}");
        
        
      }
      applyTheme();

      
      `;


      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      }
    }
  }, [theme]);


  function fireNavBarCondition() {
    const basePath = location?.pathname?.split("/")[1];

    const navHideArray = [];

    if (navHideArray.includes(basePath.toLowerCase())) setShowNavBar(false);
    else setShowNavBar(true);

  }



  function resetTheme() {
    setTheme(getThemeData())
  }

  async function checkUser() {
    let newuser = await supabase.auth.getUser();

    setLoading(false);
    if (newuser?.data?.user?.id === user?.id) return
    setUser(newuser?.data?.user);
  }
  async function signOut() {
    await supabase.auth.signOut();
    setUser(null);
  }

  function redirect(to) {
    navigate(to, { replace: true });
  }

  async function handleThemeChange(color, mode) {
    setTheme(getThemeData(color, mode));
    localStorage.setItem("theme", JSON.stringify({ color, mode }));

    if (user) {
      const { data, error } = await supabase
        .from('profiles')
        .update({ p_theme: { color, mode } })
        .eq('user_id', user.id)

    }
  }



  function displayError(err) {

  }




  return (
    <>
      {loading ? (<div className="absolute top-0 bottom-0 left-0 right-0 z-50" > <LoadingView signOut={() => signOut()} /></div>) : (
        <div style={{ color: theme.mode.uicolor.hex }} className={"App font-Lato absolute top-0 bottom-0 left-0 right-0 flex flex-col bg-theme-mode-background select-none h-full overflow-scroll scrollbar-hide"}>


          {prompt ?
            <Prompt setPrompt={setPrompt} onApprove={prompt.onApprove} buttonValues={prompt.buttonValues}>
              {prompt.value}
            </Prompt>
            : ""}
          {!user ? <LoginView supabase={supabase} setUser={setUser} setPrompt={setPrompt} resetTheme={resetTheme} /> : (


            <IconContext.Provider value={{ color: `${theme.color.hex}`, size: "28" }}>
              <PageContextProvider value={{ theme, setTheme, handleThemeChange, prompt, setPrompt, popUp, setPopUp, showNavBar, setShowNavBar, navigate, redirect, user, profile, signOut, snowflake }}>

                {!profile ? <div className='absolute top-0 bottom-0 left-0 right-0 bg-theme-mode-background'><LoadingView theme={theme} signOut={signOut} /></div> : !profile.username || !profile.name ? <ProfileSetupView user={user} profile={profile} handleThemeChange={handleThemeChange} getProfile={getProfile} /> :



                  <>

                    <div className='grow w-screen overflow-y-scroll scrollbar-hide'>
                      <div className='flex justify-center'>
                        <AnimatePresence
                          initial={false}
                          exitBeforeEnter={true}
                        >
                          {popUp ?
                            <div className='absolute top-14 bottom-0 max-w-2xl w-full'>
                              <PopUp title={popUp.title}>
                                {!popUp?.element ? "" : popUp?.element?.props ? (popUp.element) : <popUp.element />}
                              </PopUp>
                            </div>
                            : ""
                          }
                        </AnimatePresence>
                      </div>





                      <Routes>
                        <Route path="/" element={<Navigate to="/gratitude" />} />

                        <Route path="/gratitude" element={<GratitudeView />} />
                        <Route path="/prayer" element={<PrayerView />} />
                        <Route path="/people" element={<PeopleView />} />
                        <Route path="/sessions" element={<SelectSessionView />} />
                        <Route path="/profile" element={<ProfileView />} />

                        <Route path="/settings" element={<SubView title="Settings" navigateBackTo="/profile" element={<SettingsView theme={theme} />} />} />
                        <Route path="/help" element={<SubView title="Help" navigateBackTo="/home" element={<HelpView theme={theme} />} />} />
                        <Route path="/privacypolicy" element={<SubView title="Privacy" navigateBackTo="/settings" element={<PrivacyPolicy />} />} />
                        <Route path="/friends" element={<SubView title="Friends" navigateBackTo="/profile" element={<FriendsView />} />} />

                      </Routes>
                    </div>

                    <AnimatePresence
                      initial={false}
                      exitBeforeEnter={true}
                    >
                      {showNavBar ?
                        <motion.div
                          variants={navBarAnimation}
                          initial="hidden"
                          animate="visible"
                          exit="exit">
                          <NavBar>
                            <NavBarIcon navigateTo="/gratitude" selected={("gratitude" == basePath)}>
                              <FaHeart />
                            </NavBarIcon>
                            <NavBarIcon navigateTo="/prayer" selected={("prayer" == basePath)}>
                              <FaPray />
                            </NavBarIcon>
                            <NavBarIcon navigateTo="/people" selected={("people" == basePath)}>
                              <BsFilePerson />
                            </NavBarIcon>
                            <NavBarIcon navigateTo="/sessions" selected={("sessions" == basePath)}>
                              <BsFillLightningChargeFill />
                            </NavBarIcon>
                            {/* <NavBarIcon navigateTo="/sessions" selected={("sessions" == basePath)}>

                            </NavBarIcon> */}
                          </NavBar>
                        </motion.div>
                        : ""}
                    </AnimatePresence>
                  </>
                }
              </PageContextProvider>
            </IconContext.Provider>
          )
          }
        </div>
      )
      }
    </>
  );

}


export default App;
