import { useState, useEffect, useContext } from 'react';

import { PageContext } from '../PageContextProvider';


// Components
import ContentArea from '../ContentArea';
import { Button, SecondaryButton } from '../Buttons';


// Functions
import hexToRgb from '../../functions/hex2rgb';
import getTextColor from '../../functions/getTextColor';
import { getTimeInfo } from '../../functions/time';


//Icons
import { IconContext } from 'react-icons/lib';
import { FaStar, FaRegStar, FaTrash } from 'react-icons/fa';
import { BsPinAngleFill, BsPinAngle, BsFillPencilFill } from 'react-icons/bs';
import { PersonRowOnCard } from './NoteCard';




function NoteCardPrompt({ id, onClose, title, description, created_at, semiTransparent, color, starred, answered, pinned, starrable, pinnable, onPin = () => { }, onStar = () => { }, onDelete = () => { }, onEdit = () => { }, person }) {


    const descriptionArray = description?.split("\n");


    const { theme, setPrompt } = useContext(PageContext)

    if (!color || color === "") color = theme.color.hex

    const [loaded, setLoaded] = useState(false);

    const [textColor, setTextColor] = useState(getTextColor(hexToRgb(color)));
    const [transparentColor, setTransparentColor] = useState(getTransparentColorCss(color));
    const [colors, setColors] = useState({
        background: (semiTransparent ? transparentColor : color),
        title: (semiTransparent ? color : textColor),
        text: (semiTransparent ? theme.mode.uicolor.hex : textColor),
    });

    const [star, setStar] = useState(starred);
    const [pin, setPin] = useState(pinned);


    useEffect(() => {
        setTimeout(() => setLoaded(true), 50);

    }, [])

    useEffect(() => {
        setStar(starred);
        setPin(pinned);
    }, [starred, pinned])


    useEffect(() => {
        const textColor = getTextColor(hexToRgb(color))
        setTextColor(textColor)
        const transparentColor = getTransparentColorCss(color)
        setTransparentColor(transparentColor)
        setColors({
            background: (semiTransparent ? transparentColor : color),
            title: (semiTransparent ? color : textColor),
            text: (semiTransparent ? theme.mode.uicolor.hex : textColor),
        })
    }, [color])

    function getTransparentColorCss(hex) {
        const rgb = hexToRgb(hex);
        return `rgba(${rgb.r},${rgb.g},${rgb.b},0.2)`;
    }



    function onPinUpdate() {
        setPin(!pin)
        onPin(id)
    }

    function onStarUpdate() {
        setStar(!star)
        onStar(id)
    }


    return (
        <>
            <div className='absolute top-0 bottom-0 right-0 left-0 z-50 flex justify-center items-center bg-neutral-700/[0.5]' onClick={onClose}>

            </div>
            <div style={{position: "absolute", top: "50%", bottom: "50%", left: "50%", right: "50%", transform: "translate(-50%, -50%)"}} className='absolute z-50 w-full max-w-2xl flex justify-center items-center h-0'>
                <div className='max-w-2xl w-full h-fit mx-6'>
                    <div className={'bg-theme-mode-background rounded-2xl ' + (loaded ? "animate-in" : " cardprompt-out")}>
                        <IconContext.Provider value={{ color: (semiTransparent ? color : textColor), size: "20" }}>
                            <div onClick={() => { }} style={{ backgroundColor: (semiTransparent ? transparentColor : color), color: (semiTransparent ? theme.mode.uicolor.hex : textColor) }} className={'w-full p-5 rounded-2xl h-full shadow-x flex flex-col '}>
                                {person && <PersonRowOnCard colors={colors} person={person} />}
                                <div className='font-extrabold text-2xl break-all flex items-center justify-between w-full'>
                                    <div style={{ color: (semiTransparent ? color : textColor) }} className='line-clamp-1'>
                                        {title}
                                    </div>
                                    {
                                        starrable ?
                                            <ButtonWithIcon onClick={() => onStarUpdate()} icon={(star ? (<FaStar size={"25"} />) : (<FaRegStar size={"25"} />))} />
                                            : pinnable ?
                                                <ButtonWithIcon onClick={() => onPinUpdate()} icon={(pin ? (<BsPinAngleFill size={"25"} />) : (<BsPinAngle size={"25"} />))} />
                                                :
                                                ""
                                    }
                                </div>
                                <div style={{ color: (semiTransparent ? color : textColor) }} className='-mt-2 font-light opacity-90'>
                                    {created_at && getTimeInfo(created_at)}
                                </div>
                                {description && description !== "" && <div style={{ borderColor: colors.title }} className='break-words text-ellipsis w-full overflow-y-scroll h-32 p-3 rounded-lg mt-3.5 border-2'>
                                    {descriptionArray.map((desc, i) => (
                                        <span key={i}>
                                            {desc}
                                            <br />
                                        </span>
                                    ))}
                                </div>}
                                <div className='flex items-center justify-center w-full mt-6'>
                                    <Button onClick={() => onEdit(id)} center color={(semiTransparent ? color : textColor)} fill><BsFillPencilFill /><div className='px-1' />Edit</Button>
                                    <div className='px-2'></div>
                                    <Button onClick={() => onDelete(id)} center color={(semiTransparent ? color : textColor)} fill><FaTrash /><div className='px-1' />Delete</Button>

                                </div>
                            </div>
                        </IconContext.Provider>
                    </div>

                </div>
            </div>


        </>
    );
}


function ButtonWithIcon({ icon, onClick }) {
    return (
        <div className='clickable py-2 px-0 rounded' onClick={onClick}>
            {icon}
        </div>
    )
}

export default NoteCardPrompt;