function MiniInfo({ icon, text, color }) { // icon must be size 18
    return (
        <div className="flex justify-start items-center p-1">
            <div>
                {icon} 
            </div>
            <div style={{color}} className={"font-semibold text-sm ml-2 " + (color ? "" : " text-theme-color")}>
                {text}
            </div>
        </div>
    )
}


export default MiniInfo;