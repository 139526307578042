import React from 'react';




function NavBar({ children }) {

    return (
        <div className={`absolute bottom-0 right-0 left-0 flex flex-row justify-around w-full p-2 border-t border-theme-color-02 bg-theme-mode-background h-13`}>
            {children}
        </div>
    );
}

export default NavBar;