import { useState, useEffect, useContext } from 'react';

import { PageContext } from '../../components/PageContextProvider';

import { supabase } from "../../client";
import ContentArea from '../../components/ContentArea';


import { AnimatePresence, motion } from 'framer-motion';

// Components
import BannerBox from '../../components/banners/BannerBox';
import ProgressLine from '../../components/progress/ProgressLine';
import NoteCard from '../../components/cards/NoteCard';
import { SessionInfo } from './SelectSessionView';
import { Button } from '../../components/Buttons';

// Icons
import { BsFilePerson, BsFillCheckCircleFill, BsLightningChargeFill, BsPeopleFill } from "react-icons/bs"
import { FaCheck, FaHeart, FaPray } from "react-icons/fa"
import { MdClose } from "react-icons/md";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io"
import { TbZoomQuestion } from "react-icons/tb";


// conf


function SessionView({ limits, closeSession, sessionName }) { // limits = [3, 5, 2];


    const { theme } = useContext(PageContext)


    const [session, setSession] = useState(null);
    const [error, setError] = useState(false);
    const [page, setPage] = useState({ current: "start", last: "start" });
    const [disabledArrows, setDisabledArrows] = useState({ left: true, right: false })
    const [progress, setProgress] = useState(0);


    const pageOrder = getPageOrder();

    useEffect(() => {
        getSessionData();
    }, [])


    useEffect(() => {
        reloadArrows();
        calculateProgress();
    }, [page])




    function getPageOrder() {
        let order = ["start"];
        if (limits[0] && limits[0] > 0) order.push("gratitude");
        if (limits[1] && limits[1] > 0) order.push("prayer");
        if (limits[2] && limits[2] > 0) order.push("people");
        order.push("done");
        return order;
    }

    async function getSessionData(g_limit = 4, p_limit = 3, pp_limit = 5) {
        let { data, error } = await supabase
            .rpc('prayersession', {
                g_limit,
                p_limit,
                pp_limit
            })

        if (error) setError(true);

        if (data) setSession(data);
    }






    function reloadArrows() {
        const index = pageOrder.indexOf(page.current);
        setDisabledArrows({ left: (!pageOrder?.[index - 1]), right: (!pageOrder?.[index + 1]) })
    }

    function calculateProgress() {
        const index = pageOrder.indexOf(page.current);
        // if (index <= 0) return setProgress(0);
        if (index >= pageOrder.length + 1) return setProgress(120);
        const length = pageOrder.length - 1;


        const percentage = 100 / (length / (index))

        setProgress(Math.round(percentage))
    }


    function nextPage() {
        const index = pageOrder.indexOf(page.current);
        setPage({ current: (pageOrder?.[index + 1] ? pageOrder?.[index + 1] : page.current), last: (pageOrder?.[index + 1] ? page.current : page.last) })
    }

    function previousPage() {
        const index = pageOrder.indexOf(page.current);
        setPage({ current: (pageOrder?.[index - 1] ? pageOrder?.[index - 1] : page.current), last: (pageOrder?.[index - 1] ? page.current : page.last) })
    }



    return (
        <>

            <div className='absolute top-0 bottom-0 left-0 right-0 bg-theme-mode-background z-50 h-full overflow-hidden'>


                {
                    error ?
                        <div className='w-full h-full flex flex-col justify-center items-center text-red-500 font-bold text-2xl' >
                            <div className='mb-2'>Error :(</div>
                            <Button onClick={() => closeSession()}>
                                Close
                            </Button>
                        </div>
                        :

                        <>
                            {page.current === "start" && <StartSession limits={limits} theme={theme} />}
                            {page.current === "gratitude" && <GratitudeSession cards={session?.gratitude} />}
                            {page.current === "prayer" && <PrayerSession cards={session?.prayer} />}
                            {page.current === "people" && <PeopleSession cards={session?.people_prayer} />}
                            {page.current === "done" && <DoneSession onClose={closeSession} sessionName={sessionName} />}
                        </>

                }

            </div>
            <div className='absolute z-50 rounded-lg p-1 top-2 left-2' onClick={closeSession}>
                <MdClose size="32" />
            </div>
            <ArrowButton disabled={disabledArrows.left} onClick={() => previousPage()} />
            <ArrowButton disabled={disabledArrows.right} position="right" onClick={() => nextPage()} />
            <div className='absolute z-50 bottom-0 right-0 left-0'>
                <ProgressLine
                    visualParts={[
                        {
                            percentage: `${progress}%`,
                            color: theme.color.hex
                        }
                    ]}
                />
            </div>

        </>
    );





    function ArrowButton({ position = "left", onClick = () => { }, disabled }) {
        const [loaded, setLoaded] = useState(false);
        useEffect(() => {
            setLoaded(true);
        }, [])
        return (
            <>
                {!disabled && <div
                    onClick={onClick}
                    className={'absolute z-50 bottom-4 h-12 w-12 flex justify-center items-center ' + (position == "left" ? " left-4 " : " right-4 ") + (loaded ? " animate-in " : " scale-out")}>
                    <div className='clickable h-10 w-10 md:h-full md:w-full p-1 bg-theme-mode-primary rounded-full flex justify-center items-center'>
                        {position === "left" ?
                            <IoIosArrowBack size="20" />
                            :
                            <IoIosArrowForward size="20" />
                        }
                    </div>
                </div>
                }
            </>

        )
    }
}


function Page({ title, children, arrowLeft, arrowRight }) { // arrowLeft and arrowRight mutst be onClick function else they wont be rendered
    return (
        <div className='absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center h-full'>
            <div className='p-3 text-theme-color text-2xl font-[1000]'>
                {title}
            </div>
            <ContentArea>
                {children}
            </ContentArea>
        </div>

    )



}



function StartSession({ limits = [], theme }) {
    return (
        <Page title={(
            <div className="flex justify-center items-center">
                <BsLightningChargeFill size={21} />
                <div className="ml-2">
                    Session
                </div>
            </div>
        )}>
            <BannerBox title="Welcome to sessions.">
                Your time to be grateful and pray.
            </BannerBox>
            <div className='w-full p-4 text-lg font-semibold flex flex-col'>
                <div>The next pages will randomly select cards from the following categories:</div>
                <div className='flex flex-col items-start my-6'>
                    {limits[0] > 0 && <SessionInfo textColored icon={<FaHeart color={theme.color.hex} size={20} />} amount={`${limits[0] || 0} Gratitude card${limits[0] > 1 ? "s" : ""}`} />}
                    {limits[1] > 0 && <SessionInfo textColored icon={<FaPray color={theme.color.hex} size={20} />} amount={`${limits[1] || 0} Prayer card${limits[1] > 1 ? "s" : ""}`} />}
                    {limits[2] > 0 && <SessionInfo textColored icon={<BsFilePerson color={theme.color.hex} size={20} />} amount={`${limits[2] || 0} People card${limits[2] > 1 ? "s" : ""}`} />}
                </div>
                <div>
                    Press the bottom right button and start praying
                </div>
            </div>
        </Page>
    )
}


function GratitudeSession({ cards }) {
    return (
        <Page title={(
            <div className="flex justify-center items-center">
                <FaHeart size={21} />
                <div className="ml-2">
                    Gratitude
                </div>
            </div>
        )}>
            {
                cards && cards[0] ?
                    <div className='mt-4 pb-4'>
                        {cards.map((card, i) => (
                            <NoteCard key={i} {...card} trueTextFormat showDate />
                        ))}
                    </div>
                    :
                    <MissingCards />
            }

        </Page>
    )
}

function PrayerSession({ cards }) {
    return (
        <Page title={(
            <div className="flex justify-center items-center">
                <FaPray size={21} />
                <div className="ml-2">
                    Prayer
                </div>
            </div>
        )}>
            {
                cards && cards[0] ?
                    <div className='mt-4 pb-4'>
                        {cards.map((card, i) => (
                            <NoteCard key={i} {...card} trueTextFormat showDate semiTransparent />
                        ))}
                    </div>
                    :
                    <MissingCards />
            }
        </Page>
    )
}

function PeopleSession({ cards }) {
    return (
        <Page title={(
            <div className="flex justify-center items-center">
                <BsFilePerson size={21} />
                <div className="ml-2">
                    People
                </div>
            </div>
        )}>
            {
                cards && cards[0] ?
                    <div className='mt-4 pb-4'>
                        {cards.map((card, i) => (
                            <NoteCard key={i} {...card} person={card} trueTextFormat showDate semiTransparent />
                        ))}
                    </div>
                    :
                    <MissingCards />
            }
        </Page>
    )
}


function DoneSession({ sessionName, onClose }) {
    return (
        <Page title={(
            <div className="flex justify-center items-center">
                <FaCheck size={21} />
                <div className="ml-2">
                    Done
                </div>
            </div>
        )}>
            <div className='flex flex-col justify-center items-center h-full'>
                <motion.div animate={{ scale: "100%", y: "0px" }} initial={{ scale: "0%", y: "-20px" }}>
                    <BsFillCheckCircleFill size="95" />
                </motion.div>
                <motion.div animate={{ opacity: "100%" }} transition={{ delay: 0.7 }} initial={{ opacity: "0%" }} className="flex flex-col justify-start items-center pt-8">
                    <div className='font-bold text-lg text-theme-color text-center'>
                        You completed your session 🎉
                    </div>
                    {sessionName && <div className='font-semibold text-normal text-theme-color-05'>
                        {sessionName}
                    </div>}
                    <div className='pt-8'>
                        <Button onClick={() => onClose()}>
                            Close
                        </Button>
                    </div>
                </motion.div>
            </div>
        </Page>
    )
}




function MissingCards({ onNext }) {
    return (
        <div className='h-full flex justify-center items-center w-full'>
            <TbZoomQuestion /><div className='ml-2'>We couldn't find anything :/</div>
        </div>
    )
}


export default SessionView;