
import { useEffect, useState } from "react";

import { supabase } from "../../../client";

//Components
import Form, { FormRow } from "../../../components/form/Form";
import NoteCard from "../../../components/cards/NoteCard";
import LargeTextInput from "../../../components/form/LargeTextInput";
import LargeTextArea from "../../../components/form/LargeTextArea";
import { SecondaryButton } from "../../../components/Buttons";


// Icons 
import { MdShortText, MdOutlineTextFields } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";


function AddPeoplePrayerView({ onAdd, person }) {

    const [loading, setLoading] = useState(false)

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [showDescription, setShowDescription] = useState(false);

    const [invalid, setInvalid] = useState();
    const [invalidMessage, setInvalidMessage] = useState();


    const [errorMessage, setErrorMessage] = useState("");


    useEffect(() => {
        setDescription("");
        setTitle("");
        setShowDescription(false);
    }, [])



    useEffect(() => {
        let invalid = false;
        let invalidMessage;
        if (title.length <= 0) invalid = true;
        if (description.length > 500) {
            invalid = true;
            invalidMessage = "Description cannot be longer than 500 characters."
        }
        if (title.length > 30) {
            invalid = true;
            invalidMessage = "Title must be between 1 & 30 characters."
        }
        setInvalid(invalid);
        setInvalidMessage(invalidMessage);
    }, [title, description])


    function handleTitleChange(title) {
        setTitle(title);
    }

    function handleDescriptionChange(description) {
        setDescription(description);
    }




    async function insertPrayer() {
        setLoading(true);
        const { data, error } = await supabase
            .from('p_people_prayer')
            .insert([
                { title: title, description: (description != "" ? description : null), people_id: person?.id },
            ])
            

        if (error) setErrorMessage("Error, try again!")
        if (!error) onAdd(person.id);

        setLoading(false);
    }

    return (
        <div className="felx flex-col">
            {title || description ? <NoteCard person={person} color={person?.color} semiTransparent {...{ title, description }} /> : ""}
            <div className="mt-4">
                <Form buttonText={"Save"} invalid={invalid} invalidMessage={invalidMessage} error={errorMessage} loading={loading} onSubmit={() => insertPrayer()} disabled={loading}>
                    <FormRow>
                        <LargeTextInput placeholder={"Title"} value={title} icon={<MdOutlineTextFields size="20" />} onChange={handleTitleChange} />
                    </FormRow>
                    <FormRow>
                        {showDescription ?
                            <LargeTextArea placeholder={"Description"} value={description} onChange={(text) => {handleDescriptionChange(text)}} />
                            :
                            <SecondaryButton onClick={() => setShowDescription(true)}><AiOutlinePlus size={16} /><div className='mr-2' />description</SecondaryButton>
                        }
                    </FormRow>


                </Form>
            </div>
            <div className="h-1/2" />

        </div>
    );
}

export default AddPeoplePrayerView;