
import { useEffect, useState } from "react";
import { supabase } from "../../client";

//Components
import Form, { FormRow } from "../../components/form/Form";
import NoteCard from "../../components/cards/NoteCard";
import LargeTextInput from "../../components/form/LargeTextInput";
import LargeTextArea from "../../components/form/LargeTextArea";
import { SecondaryButton } from "../../components/Buttons";


// Icons 
import { MdShortText, MdOutlineTextFields } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";


function EditGratitudeView({ data = {}, onEdit }) {

    const [loading, setLoading] = useState(false)

    const [title, setTitle] = useState(data.title || "");
    const [description, setDescription] = useState(data.description || "");
    const [showDescription, setShowDescription] = useState(description && description !== "" );

    const [invalid, setInvalid] = useState();
    const [invalidMessage, setInvalidMessage] = useState();
    const [errorMessage, setErrorMessage] = useState("");


    useEffect(() => {
        setTitle(data.title || "");
        setDescription(data.description || "");
        setShowDescription(data.description && data.description !== "" )
    }, [data])


    function handleTitleChange(title) {
        let invalid = false;
        let invalidMessage;
        if (title.length <= 0) invalid = true;
        if (title.length > 30) {
            invalid = true;
            invalidMessage = "Title must be between 1 & 30 characters."
        }
        setTitle(title);
        setInvalid(invalid);
        setInvalidMessage(invalidMessage);
    }

    function handleDescriptionChange(description) {
        let invalid = false;
        let invalidMessage;
        if (description.length > 500) {
            invalid = true;
            invalidMessage = "Description cannot be longer than 500 characters."
        }
        setDescription(description);
        setInvalid(invalid);
        setInvalidMessage(invalidMessage);
    }



    async function updateGratitude() {
        setLoading(true);
        const { error } = await supabase
            .from('p_gratitude')
            .update({ title, description })
            .eq('id', data.id)

        if (error) setErrorMessage("Error, try again!")
        if (!error) onEdit();

        setLoading(false);
    }

    return (
        <div className="felx flex-col">
            <NoteCard {...{ title, description }} />
            <div className="mt-4">
                <Form buttonText={"Save"} invalid={invalid} invalidMessage={invalidMessage} error={errorMessage} loading={loading} onSubmit={() => updateGratitude()} disabled={loading}>
                    <FormRow>
                        <LargeTextInput placeholder={"Title"} value={title} icon={<MdOutlineTextFields size="20" />} onChange={handleTitleChange} />
                    </FormRow>
                    <FormRow>
                        {showDescription ?
                            <LargeTextArea placeholder={"Description"} value={description} icon={<MdShortText size="20" />} onChange={handleDescriptionChange} />
                            :
                            <SecondaryButton onClick={() => setShowDescription(true)}><AiOutlinePlus size={16} /><div className='mr-2' />description</SecondaryButton>
                        }
                    </FormRow>

                </Form>
            </div>
            <div className="h-1/2" />

        </div>
    );
}

export default EditGratitudeView;