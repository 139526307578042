import { useContext, useState, useEffect } from 'react';




import { motion, AnimateSharedLayout, LayoutGroup } from 'framer-motion';

import { PageContext } from '../PageContextProvider';


import DragIcon from '../drag/DragIcon';

// Functions
import hexToRgb from '../../functions/hex2rgb';
import getTextColor from '../../functions/getTextColor';
import getTransparentColorCss from '../../functions/getTransparentColor';


// Components
import { SecondaryButton } from '../Buttons';



//Icons
import { IconContext } from 'react-icons/lib';
import { BsFillPencilFill, BsPlusLg } from "react-icons/bs";
import { FaTrash } from 'react-icons/fa';
import PrayersCol from '../../views/Prayer/PrayersCol';

function PeopleCard({ semiTransparent, name, id, img, color, onClick = () => { }, onPrayerClick = () => { }, onPrayerPin = () => { }, onPrayerAnswered = () => { }, dragOptions, noAnimate, iconBadges, altDragIcons = { left: false, right: false }, onAdd = () => { }, onEdit = () => { }, onDelete = () => { }, expanded, getPeoplePrayers = () => { }, prayerObject, loadError }) {

    const { theme, setPrompt } = useContext(PageContext)

    if (!color || color === "") color = theme.color.hex;

    const [loaded, setLoaded] = useState(noAnimate || false);

    const [drag, setDrag] = useState(null);
    const [dragStart, setDragStart] = useState(null);

    const [textColor, setTextColor] = useState(getTextColor(hexToRgb(color)));
    const [transparentColor, setTransparentColor] = useState(getTransparentColorCss(color))

    const [peoplePrayers, setPeoplePrayers] = useState();

    const DragDistance = {
        x: 100
    }





    if (dragOptions) dragOptions.get = dragOptionsGet;





    useEffect(() => {
        if (dragOptions) dragOptions.get = dragOptionsGet;

        setTimeout(() => setLoaded(true), 50);
    }, [])

    useEffect(() => {
        const textColor = getTextColor(hexToRgb(color))
        setTextColor(textColor)
        const transparentColor = getTransparentColorCss(color)
        setTransparentColor(transparentColor)
        loadPrayers();
    }, [color])

    useEffect(() => {
        if (drag && dragOptions[drag] && navigator?.vibrate) {
            // vibration API supported
            navigator?.vibrate(10);
        }
    }, [drag])

    useEffect(() => {
        if (expanded) {
            loadPrayers()
        }
    }, [expanded])

    useEffect(() => {
        if (expanded) {
            loadPrayers();
        }
    }, [prayerObject])

    async function loadPrayers() {
        const peoplePrayers = await getPeoplePrayers(id);
        setPeoplePrayers(peoplePrayers);
    }

    function dragOptionsGet(side, options = {}) {
        return { ...dragOptions[side], icon: (options?.alt ? dragOptions[side].altIcon : dragOptions[side].icon) }
    }


    function handleOnClick(id) {
        if (!drag) onClick(id)
    }


    function handlePrayerClick(prayer_id) {
        onPrayerClick(id, prayer_id)
    }

    function handlePrayerPin(prayer_id) {
        onPrayerPin(id, prayer_id)
    }

    function handlePrayerAnswer(prayer_id) {
        onPrayerAnswered(id, prayer_id)
    }


    if (!dragOptions) return (
        <div style={{ backgroundColor: (semiTransparent ? transparentColor : color), color: (semiTransparent ? theme.mode.uicolor.hex : textColor) }} onClick={() => onClick(id)} className={"clickable w-full p-3 my-2 rounded-full card flex justify-start items-center " + (loaded || noAnimate ? "animate-in " : "animate-out ")}>
            <div style={{ borderColor: (semiTransparent ? theme.mode.uicolor.hex : textColor) }} className='border-4 rounded-full w-9 h-9 mr-2.5 flex justify-center items-center'>
                {img ?
                    <></>
                    :
                    <div className='font-normal text-md'>
                        {name[0].toUpperCase()}
                    </div>}
            </div>
            <div style={{ color: (semiTransparent ? color : textColor) }} className='font-extrabold text-xl break-all'>
                {name}
            </div>
        </div>
    )


    function handleDragRelease(dragDirection) {
        dragOptions?.[dragDirection]?.onDragged(id);
    }






    return (
        <motion.div layout layoutScroll shouldMeasureScroll className='w-full h-fit flex flex-col items-center justify-center '>


            <LayoutGroup layoutScroll shouldMeasureScroll>
                <motion.div layout layoutScroll shouldMeasureScroll className='w-full h-fit relative'>
                    <motion.div
                        drag={"x"}
                        dragConstraints={{ left: "-100", right: "100" }}
                        dragSnapToOrigin
                        dragElastic={{ left: 0 }}
                        whileHover={{ scale: 1 }}
                        whileTap={{ scale: 1, cursor: "grabbing" }}
                        transition={{ duration: 0.1 }}
                        dragTransition={{ duration: 0.1, type: 'inertia', stiffness: 60 }}
                        onDragStart={
                            (event, info) => setDragStart({ ...info.point })
                        }
                        onDrag={
                            (event, info) => {
                                if (dragStart) {
                                    let moved = ((dragStart?.x - info.point.x) - 10)

                                    if (moved >= 0) { // left
                                        if (moved <= DragDistance.x - 1) {
                                            if (drag) setDrag(null)
                                        } else if (moved > DragDistance.x) {
                                            if (!drag) setDrag("left");
                                        }
                                    } else {
                                        if ((moved * -1) <= DragDistance.x - 1) {
                                            if (drag) setDrag(null)
                                        } else if ((moved * -1) > DragDistance.x) {
                                            if (!drag) setDrag("right");
                                        }
                                    }



                                }

                            }
                        }
                        onDragEnd={
                            (event, info) => {
                                setTimeout(() => {
                                    handleDragRelease(drag);
                                    setDrag(null)
                                }, 150);
                            }
                        }
                    >
                        <div style={{ backgroundColor: (semiTransparent ? transparentColor : color), color: (semiTransparent ? theme.mode.uicolor.hex : textColor) }} onClick={() => handleOnClick(id)} className={"relative clickable w-full p-3 my-2 rounded-full card flex justify-start items-center " + (loaded || noAnimate ? "animate-in " : "animate-out ")}>
                            <div className='text-xl break-all flex items-center justify-between w-full'>
                                <div className='flex items-center'>
                                    <div style={{ borderColor: (semiTransparent ? theme.mode.uicolor.hex : textColor) }} className='border-4 rounded-full w-9 h-9 mr-2.5 flex justify-center items-center'>
                                        {img ?
                                            <></>
                                            :
                                            <div className='font-normal text-sm'>
                                                {name[0].toUpperCase()}
                                            </div>}
                                    </div>
                                    <div style={{ color: (semiTransparent ? color : textColor) }} className='font-extrabold text-xl break-all'>
                                        {name}
                                    </div>
                                </div>
                                {iconBadges && <IconContext.Provider value={{ color: (semiTransparent ? color : textColor), size: "20" }}>
                                    <div className='flex justify-end items-center mr-1'>
                                        {iconBadges.map((badge, i) => (badge ? (<div className='ml-3' key={i}>{badge}</div>) : ""))}
                                    </div>
                                </IconContext.Provider>}
                            </div>


                        </div>
                    </motion.div >


                    {drag && <DragIcon position={drag} icon={drag === "left" ? dragOptions.get("left", { alt: altDragIcons.left })?.icon : dragOptions.get("right", { alt: altDragIcons.right })?.icon} />
                    }
                </motion.div>
                {expanded && <motion.div layout layoutScroll shouldMeasureScroll className='w-full px-4 mb-2'>
                    <ButtonRow {...{ id, color, onAdd, onDelete, onEdit }} />
                    <div className='my-5' />
                    {loadError ?
                        <div className='flex justify-center items-center w-full text-xl font-bold text-red-500 mb-5'>
                            Error :(
                        </div>
                        :
                        <PrayersCol onClick={handlePrayerClick} color={color} prayers={peoplePrayers?.filter(p => !p.pinned) || []} pinnedPrayers={peoplePrayers?.filter(p => p.pinned) || []} onPin={handlePrayerPin} onAnswered={handlePrayerAnswer} />
                    }
                </motion.div>}
            </LayoutGroup>
        </motion.div>
    );
}





function ButtonRow({ id, color, onAdd, onEdit, onDelete }) {
    return (
        <motion.div
            initial={{
                y: "-50px"
            }}
            animate={{
                y: 0
            }}
        >
            <div className="w-full flex">
                <SecondaryButton onClick={() => onAdd(id)} color={color} fill><div className='flex justify-center items-center w-full'><BsPlusLg size="12" color={color} /><div className='ml-2 sm:flex hidden'>Add Prayer</div></div></SecondaryButton>
                <div className='px-1' />
                <SecondaryButton onClick={() => onEdit(id)} color={color} fill><div className='flex justify-center items-center w-full'><BsFillPencilFill size="12" color={color} /><div className='ml-2 sm:flex hidden'>Edit Person</div></div></SecondaryButton>
                <div className='px-1' />
                <SecondaryButton onClick={() => onDelete(id)} color={color} fill><div className='flex justify-center items-center w-full'><FaTrash size="12" color={color} /><div className='ml-2 sm:flex hidden'>Delete Person</div></div></SecondaryButton>
            </div>
        </motion.div>

    )
}

export default PeopleCard;