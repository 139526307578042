
function Tab({ buttons, selected }) { // buttons: {name: string, onClick: function} // selected: string (matches buttons.name)

    return (
        <div className="flex items-center justify-between w-full h-12 rounded-full bg-theme-mode-primary">
            {buttons.map(button => (
                <TabButton key={button.name} {...button} selected={selected === button.name} />
            ))}
        </div>
    );
}


export function TabButton({ selected, name, onClick }) {
    return (
        <div onClick={onClick} className={"clickable h-12 flex items-center justify-center w-full rounded-full text-md font-semibold " + (selected ? " bg-theme-color-02 text-theme-color" : " text-theme-mode-text")}>
            {name}
        </div>
    )
}

export default Tab;