import React, { useContext } from 'react';

import { SecondaryButton } from '../components/Buttons';


function TryAgainView({ tryAgain = () => { } }) {

    return (
        <div className='h-96 w-full flex flex-col justify-center items-center'>
            <div className='text-theme-color text-2xl font-extrabold'>
                Something went wrong :(
            </div>
            <div className='mt-4'><SecondaryButton onClick={tryAgain}>Try Again?</SecondaryButton></div>
        </div>
    );
}

export default TryAgainView;