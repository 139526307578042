
import { useEffect, useState } from "react";
import { supabase } from "../../client";

//Components
import ColorSelect from "../../components/ColorSelect";
import Form, { FormRow } from "../../components/form/Form";
import PeopleCard from "../../components/cards/PeopleCard";
import LargeTextInput from "../../components/form/LargeTextInput";


// Icons 
import { BsFillPersonFill } from "react-icons/bs";


function EditPeopleView({ data = {}, onEdit = () => { } }) {

    const [loading, setLoading] = useState(false)

    const [name, setName] = useState("");
    const [color, setColor] = useState(data.color);

    const [invalid, setInvalid] = useState();
    const [invalidMessage, setInvalidMessage] = useState();


    const [errorMessage, setErrorMessage] = useState("");


    useEffect(() => {
        setName(data?.name || "");
        setColor(data?.color || "")
    }, [data])


    useEffect(() => {
        let invalid = false;
        let invalidMessage;
        if (name.length <= 0) invalid = true;
        if (name.length > 20) {
            invalid = true;
            invalidMessage = "Name must be between 1 & 20 characters."
        }
        setInvalid(invalid);
        setInvalidMessage(invalidMessage);
    }, [name])


    function handleNameChange(name) {
        setName(name);
    }





    async function upsertPerson() {
        setLoading(true);
        const { error } = await supabase
            .from('p_people')
            .update({ name, color })
            .eq('id', data.id)

        if (error) setErrorMessage("Error, try again!")
        if (!error) onEdit();

        setLoading(false);
    }

    return (
        <div className="felx flex-col">
            {name ? <PeopleCard {...{ ...data, name, color }} /> : ""}
            <div className="mt-4">
                <Form buttonText={"Update Person"} invalid={invalid} invalidMessage={invalidMessage} error={errorMessage} loading={loading} onSubmit={() => upsertPerson()} disabled={loading}>
                    <FormRow>
                        <LargeTextInput placeholder={"Name"} value={name} icon={<BsFillPersonFill size="20" />} onChange={handleNameChange} />
                    </FormRow>
                </Form>
                <div className="py-6"/>
                <ColorSelect currentColor={color} onChange={setColor}/>
            </div>
            <div className="h-1/2" />

        </div>
    );
}

export default EditPeopleView;