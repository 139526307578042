import { useContext } from 'react';

import ProfilePicture from '../profile/ProfilePicture';
import TitleLabel from '../TitleLabel';

import { PageContext } from '../PageContextProvider';

function TopBar({ wordmark, icon, clickables, title, children, innerHtml, decolor, avatar }) {

    const { redirect } = useContext(PageContext);

    return (
        <>
            {!innerHtml && <div className={`z-50 absolute top-0 right-0 left-0 flex flex-row justify-between w-full p-2 bg-theme-mode-background text-theme-color shadow-sm shadow-theme-mode-background h-14`}>
                <div className={'grow flex flex-row justify-start items-center' + (title ? " basis-1/2" : "")}>
                    <div>
                        {icon}
                    </div>

                    {(wordmark && <p className='pt-1 px-2 font-bold text-2xl text-theme-color font-wordmark drop-shadow-theme-color-02 drop-shadow-[1px_1px_1px]'>{wordmark}</p>)}
                </div>
                {(title &&
                    <TitleLabel title={title} />
                )}

                <div className='grow'>
                    {children}
                </div>


                <div className={'grow flex flex-row-reverse items-center justify-start' + (title ? " basis-1/2" : "")}>
                    {avatar && <div className='p-1' onClick={() => redirect("/profile")}><ProfilePicture size="sm" /></div>}
                    <div className='flex pr-1.5'>
                        {clickables && clickables[0] && clickables.map((clickable, i) => {
                            return (
                                <div key={i} onClick={clickable.props.onClick} className="p-3 pr-0">
                                    {clickable}
                                </div>
                            )
                        })}
                    </div>


                </div>
            </div>}
            {innerHtml && <div className={`z-50 absolute top-0 right-0 left-0 flex flex-row justify-between w-screen p-2 bg-theme-mode-background text-theme-color border-b ${decolor ? "border-theme-mode-uicolor" : "border-theme-color-02"} h-14`}>
                {innerHtml}
            </div>}
            <div className='h-14'>

            </div>
        </>
    );
}

export default TopBar;