import React from 'react';
import { useState, useEffect, useContext } from "react";
import { getThemeData, themeModes, themeColors } from '../../config/themes';

import { PageContext } from '../../components/PageContextProvider';

import Select, { SelectOption } from '../../components/form/Select';
import { Button, SecondaryButton, TertiaryButton } from "../../components/Buttons.jsx"
import ContentArea from '../../components/ContentArea';

export const ThemePopUp = () => {
    const { theme, handleThemeChange } = useContext(PageContext);


    const themeColorsExpanded = themeColors.map(color => {
        color = { ...color, selected: (theme.color.hex == color.hex), value: color.hex }
        return color
    })
    const availableColors = [{ ...theme.mode.uicolor, selected: (theme.mode.uicolor.hex == theme.color.hex), value: null }, ...themeColorsExpanded]; // this exists so user can also switch to black/white






    function handleModeChange(value) {
        handleThemeChange(theme.color.hex, value);
    }
    function handleColorChange(value) {
        handleThemeChange(value, theme.mode.name);
    }


    function capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    function addDotForColor(color) {
        const Dot = () => {
            let rgbaStr = `rgba(${color.r}, ${color.g}, ${color.b}, 0.2)`
            return (
                <div className="h-5 w-5 rounded-full bg-theme-mode-background">
                    <div style={{ backgroundColor: rgbaStr }} className='h-5 w-5 rounded-full flex justify-center items-center'>
                        <div style={{ backgroundColor: color.hex }} className='h-3 w-3 rounded-full'>

                        </div>
                    </div>
                </div>
            )
        }


        return (
            <div className='flex justify-start'>
                <div className="flex items-center justify-center mr-2">
                    <Dot />
                </div>
                <div>
                    {capitalize(color.name)}
                </div>
            </div>
        )
    }


    return (
        <div className='flex flex-col justify-between divide-y divide-theme-color-02'>
            <SettingsElement>
                <p>Mode:</p>
                <Select dropdownAlign="left" setValue={handleModeChange}>
                    {themeModes.map(mode => {
                        return (
                            <SelectOption key={mode.name} selected={mode.name == theme.mode.name} value={mode.name}>{capitalize(mode.name)}</SelectOption>
                        )
                    })}

                </Select>
            </SettingsElement>
            <SettingsElement>
                <p>Color:</p>
                <Select innerElement={addDotForColor(theme.color)} dropdownAlign="left" setValue={handleColorChange}>
                    {availableColors.map((color, i) => {
                        return (
                            <SelectOption key={color.hex} customBgColor={color.hex} selected={color.selected} value={color.value}>{addDotForColor(color)}</SelectOption>

                        )
                    })}

                </Select>
            </SettingsElement>
        </div>
    )
}






function SettingsView() {
    const { navigate, setPopUp, signOut, setPrompt, user } = useContext(PageContext)



    return (
        <ContentArea>
            <div className='pt-4 flex flex-col divide-y divide-theme-color-02'>
                <SettingsElement>
                    <p>Email:</p>
                    <p className='text-theme-color select-text'>{user?.email}</p>
                </SettingsElement>
                <SettingsElement>
                    <p>Theme:</p>
                    <Button onClick={() => setPopUp({ title: "Theme", element: ThemePopUp })}>Customize</Button>
                </SettingsElement>
                <SettingsElement>
                    <p>Privacy Policy:</p>
                    <Button onClick={() => navigate("/privacypolicy")}>View</Button>
                </SettingsElement>
                <SettingsElement>
                    <div>
                    </div>
                    <TertiaryButton onClick={() => setPrompt({ onApprove: signOut, buttonValues: { approve: "Sign Out" } })}>Sign Out</TertiaryButton>
                </SettingsElement>
            </div>
        </ContentArea>
    );
}

const SettingsElement = ({ children }) => {
    return (
        <div className='flex justify-between items-center w-full py-2 text-lg font-semibold'>
            {children}
        </div>
    )
}





export default SettingsView;