import React from 'react';

function BannerBox({ children, title }) {
    return (
        <div className='p-6 bg-theme-color-02 rounded-2xl mb-4 w-full'>
            {title && <div className='font-bold text-3xl text-center pt-1 px-2 text-theme-color font-wordmark drop-shadow-theme-color-02 drop-shadow-[1px_1px_1px]'>
                {title}
            </div>}
            {children && <div className='text-theme-color font-semibold text-md mt-3 text-center'>
                {children}
            </div>}
        </div>
    );
}

export default BannerBox;