import { motion } from "framer-motion";



function ContentArea({ children, padding = "lg", pb = true }) {
    return (
        <div className={`w-full flex justify-center relative h-full
            
            `}>
            <div className={`max-w-2xl w-full overflow-y-scroll scrollbar-hide h-full 
            ${pb && "pb-20 "}
            ${padding === "xs" && "px-1 pt-1 "}
            ${padding === "sm" && "px-2 pt-2 "}
            ${padding === "md" && "px-3 pt-3 "}
            ${padding === "lg" && "px-4 pt-4 "}
            ${padding === "xl" && "px-6 pt-6 "}
            `}>
                {children}
            </div>
        </div>

    );
}

export default ContentArea;