import moment from "moment"

const dayInUnix = 1 * 24 * 60 * 60 * 1000;

export function getTimeInfo(iso) {
    var unix = moment(iso).format("x");
    var nowUnix = moment().format("x");

    if (nowUnix - dayInUnix > unix) { // older than one day
        return moment(iso).format("DD / MM / YYYY")
        return moment(iso).format("MMMM Do YYYY")
    } else { // younger than one day
        return moment(iso).format("HH:mm")
    }   
}