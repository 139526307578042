import React from 'react';

import { motion, AnimatePresence } from "framer-motion"

import { Button, TertiaryButton } from "../Buttons.jsx";

import { CgClose } from "react-icons/cg"

const validAnimation = {
    hidden: {
        y: "-7px",
        opacity: 0
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
        }
    },
    exit: {
        y: "-7px",
        opacity: 0
    }
}

function Form({children, buttonText, onSubmit, error, invalid, invalidMessage, loading, cancel, disabled}) { // cancel: func, error is error message, valid is true: "wont show text and button can be cliacked", false && "": "wont show button can be clicked", "text": (show text, cant be clicked)
    return (
        <div>
            {children}
            <AnimatePresence
                initial={false}
                exitBeforeEnter={true}>
                {invalidMessage && invalidMessage != "" &&
                    <motion.div
                        variants={validAnimation}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        className='absolute -mt-4 text-sm font-semibold text-theme-color flex justify-start items-center' >
                        <div className='mx-1'>
                            <CgClose size="16" />
                        </div>
                        {invalidMessage}
                    </motion.div>}
            </AnimatePresence>
            <div className={`flex mt-4 pb-4 ${error && error.length > 0 ? "justify-between" : "justify-end"} items-center`}>
                {error && error.length > 0 && <div className='text-red-500 font-bold text-base ml-1'>
                    {error}
                </div>}
                <div className='flex justify-end'>
                    {cancel && <TertiaryButton onClick={cancel}>Cancel</TertiaryButton>}
                    <Button disabled={disabled || invalid} onClick={onSubmit} loading={loading}>
                        {buttonText}
                    </Button>

                </div>

            </div>
        </div>
    );
}


export function FormRow ({children}) {

    return (
        <div className='w-full min-h-12 my-5 text-lg font-semibold text-theme-color flex flex-col justify-center'>
            {children}
        </div>
    )
}

export default Form;