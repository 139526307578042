import { useState, useEffect } from "react";

import colorPalettes from "../config/colorPalettes";

import AliceCarousel from "react-alice-carousel";

import { HexColorPicker } from "react-colorful";

// ComponentS
import Tab from "./Tab";

//Icons
import { BsCheckLg } from "react-icons/bs";

function ColorSelect({ currentColor, onChange }) {

    const [selectedTab, setSelectedTab] = useState("Color Picker");
    const [inputValue, setInputValue] = useState(currentColor);



    useEffect(() => {
        setInputValue(currentColor)
    }, [currentColor])

    const tabButtons = [
        {
            name: "Color Picker",
            onClick: () => setSelectedTab("Color Picker")
        },
        {
            name: "Prefabs",
            onClick: () => setSelectedTab("Prefabs")
        },
    ]


    function handleValueChange(hex) {
        const regex = /^#[0-9A-F]{6}$/i

        if (hex.length <= 7) setInputValue(hex);
        if (regex.test(hex)) onChange(hex);
        
    }

    return (
        <div className="flex flex-col items-center w-full h-12">
            <Tab buttons={tabButtons} selected={selectedTab} />
            <div className="w-full mt-4">
                {selectedTab === tabButtons?.[0]?.name ?
                    <div className="p-4  flex sm:flex-row flex-col items-center sm:items-start">
                        <div className="w-fit sm:mr-4">
                            <HexColorPicker color={currentColor} onChange={onChange} />
                        </div>
                        <input value={inputValue.toUpperCase()} onFocus={(e) => e?.select()} onChange={e => handleValueChange(e?.target?.value)} type={"text"} className="bg-theme-mode-primary w-full rounded-xl text-lg font-semibold tracking-wider p-2.5 h-fit sm:mt-0 mt-4 text-center sm:text-left" />
                    </div>
                    :
                    <ColorCarousel currentColor={currentColor || "#ff924c"} onClick={onChange} />
                }
            </div>
            {
                selectedTab === "Prefabs" ?
                    <></>
                    :
                    <></>
            }
        </div>
    );
}






export function ColorCarousel({ currentColor, onClick = () => { } }) {
    function getActiveIndex() {
        let index = 0;
        for (let i = 0; i < colorPalettes.length; i++) {
            const { colors } = colorPalettes[i];
            if (colors.filter(color => color === currentColor)[0]) index = i;
        }
        return index;
    }

    return (
        <div className='mt-4'>
            <AliceCarousel activeIndex={getActiveIndex()} mouseTracking disableButtonsControls infinite keyboardNavigation items={colorPalettes.map((palette, i) => {
                return (<ColorPalette key={i} currentColor={currentColor} onClick={onClick} palette={palette} />)
            })} />
        </div>
    )
}

function ColorPalette({ palette, onClick, currentColor }) {
    const { colors, name } = palette;
    return (
        <div className='flex flex-col'>
            <div className='py-2 text-center font-semibold text-theme-mode-text'>
                {name && name + " Palette"}
            </div>
            <div className='grid-rows-2'>
                <div className='grid-cols-5 flex w-full'>
                    {colors.map((color, i) => {
                        if (i < 5) return (
                            <ColorDot selected={color === currentColor} key={color} color={color} onClick={() => onClick(color)} />
                        )
                    })}
                </div>
                <div className='grid-cols-5 flex w-full'>
                    {colors.map((color, i) => {
                        if (i >= 5) return (
                            <ColorDot selected={color === currentColor} key={color} color={color} onClick={() => onClick(color)} />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

function ColorDot({ color, onClick, selected }) {
    return (
        <div onClick={onClick} className='w-full flex justify-center items-center'>
            <div style={{ backgroundColor: color }} className='h-8 w-8 rounded-full m-2 flex justify-center items-center'>
                {selected && <BsCheckLg color="#ffffff" size={10} />}
            </div>
        </div>
    )
}

export default ColorSelect;