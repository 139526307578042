import React, { useContext } from 'react';

import { PageContext } from './PageContextProvider';


import TopBar from './nav/TopBar';

import { IoIosArrowBack } from "react-icons/io";

export function putImageIntoClickableDiv(element, onClick) {
    return (
        <div className='p-3' onClick={onClick}>
            {element}
        </div>
    )
}

function SubView({ element, navigateBackTo, title = "", onBack }) {
    const { navigate } = useContext(PageContext)

    


    return (
        <div className='flex flex-col absolute top-0 bottom-0 right-0 left-0 bg-theme-mode-background z-20'>
            <div className='flex justify-center'>
                <TopBar title={title} icon={putImageIntoClickableDiv(<IoIosArrowBack />, (onBack ? onBack : () => navigate(navigateBackTo, { replace: true })))}>

                </TopBar>
            </div>
            <div className='grow flex justify-center overflow-scroll scrollbar-hide'>
                {element}
            </div>
        </div>
    );
}



export default SubView;