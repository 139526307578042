import React from 'react';
import { useState, useEffect, useContext } from "react";

import { PageContext } from '../../components/PageContextProvider';

function HelpView({ }) {
    const { navigate } = useContext(PageContext)

    return (
        <div>

        </div>
    );
}

export default HelpView;