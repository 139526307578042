import React from 'react';
import { useState, useEffect, useContext } from "react";

import TopBar from '../components/nav/TopBar';
import ContentArea from '../components/ContentArea';

import ProfilePicture from '../components/profile/ProfilePicture';

import { putImageIntoClickableDiv } from '../components/SubView';

import { IoMdSettings, IoIosArrowBack } from "react-icons/io";
import { BsFillPeopleFill } from "react-icons/bs";

import { PageContext } from '../components/PageContextProvider';
import { uploadProfilePicture } from '../functions/storageHelper';

function ProfileView({ }) {
    const { navigate, redirect, profile, user } = useContext(PageContext)


    async function handleImageUpload(e) {
        if (e.target.files.length !== 0) {
            const { data, error } = await uploadProfilePicture(e.target.files[0], user.id)

            console.log(data, error)
        }
    }

    return (
        <>
            <TopBar title="Profile" icon={putImageIntoClickableDiv(<IoIosArrowBack />, () => navigate("/", { replace: true }))} clickables={[<IoMdSettings className='h-6 clickable' onClick={() => redirect("/settings")} />]}>

            </TopBar>
            <div className='absolute top-0 bottom-0 right-0 left-0 z-40 bg-theme-mode-background'>

                <ContentArea>
                    <div className='w-full flex flex-col justify-start items-center mt-20'>
                        <label for="pfp">
                            <ProfilePicture size="lg" />
                        </label>
                        <input className='hidden' type="file" accept="image/*" id="pfp" onChange={(e) => handleImageUpload(e)} />

                        <div className='pt-8 flex flex-col justify-start items-center'>
                            <p className='text-theme-color text-2xl font-bold '>{profile?.name}</p>
                            <p className='text-theme-color-08 text-lg pt-0 -mt-1'>@{profile?.username}</p>
                        </div>

                    </div>
                </ContentArea>

            </div>
        </>
    );
}

export default ProfileView;