import { useContext, useState, useEffect } from 'react';




import { motion } from 'framer-motion';

import { PageContext } from '../PageContextProvider';


import DragIcon from '../drag/DragIcon';

// Functions
import hexToRgb from '../../functions/hex2rgb';
import getTextColor from '../../functions/getTextColor';
import getTransparentColorCss from '../../functions/getTransparentColor';
import { getTimeInfo } from '../../functions/time';



//Icons
import { IconContext } from 'react-icons/lib';





/* @Object dragOptions should look like this:

const dragOptions = {
    left: {
        icon: (<Icon size="20"/>),
        altIcon: (<Icon size="20"/>),
        onDragged: () => {}
    },
    right: {
        icon: (<Icon size="20"/>),
        onDragged: () => {}
    }
}

*/


function NoteCard({ trueTextFormat, showDate, semiTransparent, id, title, created_at, description, color, onClick, dragOptions, noAnimate, iconBadges, altDragIcons = { left: false, right: false }, readMore, person }) { // alt drag

    const descriptionArray = description?.split("\n");

    const { theme, setPrompt } = useContext(PageContext)

    if (!color || color === "") color = theme.color.hex;

    const [loaded, setLoaded] = useState(noAnimate || false);

    const [drag, setDrag] = useState(null);
    const [dragStart, setDragStart] = useState(null);



    const [textColor, setTextColor] = useState(getTextColor(hexToRgb(color)));
    const [transparentColor, setTransparentColor] = useState(getTransparentColorCss(color))

    const [colors, setColors] = useState({
        background: (semiTransparent ? transparentColor : color),
        title: (semiTransparent ? color : textColor),
        text: (semiTransparent ? theme.mode.uicolor.hex : textColor),
    });


    const DragDistance = {
        x: 100
    }









    useEffect(() => {
        const textColor = getTextColor(hexToRgb(color))
        setTextColor(textColor)
        const transparentColor = getTransparentColorCss(color)
        setTransparentColor(transparentColor)
        setColors({
            background: (semiTransparent ? transparentColor : color),
            title: (semiTransparent ? color : textColor),
            text: (semiTransparent ? theme.mode.uicolor.hex : textColor),
        })
    }, [color, iconBadges])



    if (dragOptions) dragOptions.get = dragOptionsGet;




    useEffect(() => {
        if (dragOptions) dragOptions.get = dragOptionsGet;

        setTimeout(() => setLoaded(true), 50);
    }, [])



    useEffect(() => {
        if (drag && dragOptions[drag] && navigator?.vibrate) {
            // vibration API supported
            navigator?.vibrate(10);
        }
    }, [drag])

    function dragOptionsGet(side, options = {}) {
        return { ...dragOptions[side], icon: (options?.alt ? dragOptions[side].altIcon : dragOptions[side].icon) }
    }







    if (!dragOptions) return (
        <div style={{ backgroundColor: colors.background, color: colors.text }} onClick={() => onClick(id)} className={"clickable w-full p-4 my-2 rounded-2xl card flex flex-col justify-start " + (loaded || noAnimate ? "animate-in " : "animate-out ")}>

            {person && <PersonRowOnCard colors={colors} person={person} />}

            <div style={{ color: colors.title }} className='font-extrabold text-xl break-all'>
                {title}
            </div>
            {showDate && <div style={{ color: (semiTransparent ? color : textColor) }} className='-mt-1 font-light opacity-90'>
                {created_at && getTimeInfo(created_at)}
            </div>}
            {description && <div className={(readMore ? "truncate overflow-hidden h-4" : 'break-words text-ellipsis w-full overflow-clip')}>
                {trueTextFormat ?
                    (description.split("\n").map((desc, i) => (
                        <span key={i}>
                            {desc}
                            <br />
                        </span>
                    )))
                    :
                    (description)}
            </div>}

        </div>
    )



    function handleDragRelease(dragDirection) {
        dragOptions?.[dragDirection]?.onDragged(id);
    }

    function handleOnClick(data) {
        if (!drag) onClick(data);
    }


    return (
        <div className='w-full h-fit relative'>
            <motion.div
                drag={"x"}
                dragConstraints={{ left: "-100", right: "100" }}
                dragSnapToOrigin
                dragElastic={{ left: 0 }}
                whileHover={{ scale: 1 }}
                whileTap={{ scale: 1, cursor: "grabbing" }}
                transition={{ duration: 0.1 }}
                dragTransition={{ duration: 0.1, type: 'inertia', stiffness: 60 }}
                onDragStart={
                    (event, info) => setDragStart({ ...info.point })
                }
                onDrag={
                    (event, info) => {
                        if (dragStart) {
                            let moved = ((dragStart?.x - info.point.x) - 10)

                            if (moved >= 0) { // left
                                if (moved <= DragDistance.x - 1) {
                                    if (drag) setDrag(null)
                                } else if (moved > DragDistance.x) {
                                    if (!drag) setDrag("left");
                                }
                            } else {
                                if ((moved * -1) <= DragDistance.x - 1) {
                                    if (drag) setDrag(null)
                                } else if ((moved * -1) > DragDistance.x) {
                                    if (!drag) setDrag("right");
                                }
                            }



                        }

                    }
                }
                onDragEnd={
                    (event, info) => {
                        setTimeout(() => {
                            handleDragRelease(drag);
                            setDrag(null)
                        }, 150);
                    }
                }
            >
                <div style={{ backgroundColor: colors.background, color: colors.text }} onClick={() => handleOnClick(id)} className={"relative clickable w-full p-4 my-2 rounded-2xl card flex flex-col justify-start " + (loaded || noAnimate ? "animate-in " : "animate-out ")}>
                    <div className='font-extrabold text-xl break-all flex items-center justify-between'>
                        <div style={{ color: colors.title }} className='line-clamp-1'>
                            {title}
                        </div>
                        {iconBadges && <IconContext.Provider value={{ color: colors.title, size: "20" }}>
                            <div className='flex justify-end items-center'>
                                {iconBadges.map((badge, i) => (badge ? (<div className='ml-3' key={i}>{badge}</div>) : ""))}
                            </div>
                        </IconContext.Provider>}
                    </div>
                    {description && <div className={"flex justify-between items-center"}>
                        <div className={(readMore ? "truncate whitespace-nowrap text-ellipsis h-6" : 'break-words text-ellipsis w-full overflow-clip h-6')}>
                            {description}
                        </div>
                        {readMore && <div className='text-neutral-400 opacity-60 ml-2'>
                            more
                        </div>}

                    </div>}

                </div>
            </motion.div>

            {drag && <DragIcon position={drag} icon={drag === "left" ? dragOptions.get("left", { alt: altDragIcons.left })?.icon : dragOptions.get("right", { alt: altDragIcons.right })?.icon} />}
        </div>
    );
}





export function PersonRowOnCard({ colors, person = {} }) {

    const { img, name } = person;

    return (
        <div className='flex items-center mb-2'>
            <div style={{ borderColor: colors.title }} className='border-2 rounded-full w-5 h-5 mr-2.5 flex justify-center items-center'>
                {img ?
                    <></>
                    :
                    <div style={{ fontSize: "0.5rem", lineHeight: "0.75rem", color: colors.title }} className='font-normal'>
                        {name[0].toUpperCase()}
                    </div>}
            </div>
            <div style={{ color: colors.title }} className='font-extrabold text-sm break-all'>
                {name}
            </div>
        </div>
    )

}




export default NoteCard;