let themeColors = [
    {
        name: "rose",
        hex: "#f43f5e",
        tailwind: "rose-500"
    },
    {
        name: "purple",
        hex: "#a855f7",
        tailwind: "purple-500"
    },
    {
        name: "blue",
        hex: "#3b82f6",
        tailwind: "blue-500"
    },
    {
        name: "teal",
        hex: "#14b8a6"
    },
    {
        name: "green",
        hex: "#16a34a",
        tailwind: "green-500"
    },
    {
        name: "amber",
        hex: "#f59e0b",
    },
    /*{
        name: "tha",
        hex: "#AF73E1",
    },
    {
        name: "pansy",
        hex: "#7D2157"
    },
    {
        name: "usafa blue",
        hex: "#20557D"
    },
    {
        name: "feldgrau",
        hex: "#406258"
    },
    {
        name: "noir",
        hex: "#55505C"
    },*/
    // {
    //     name: "navy",
    //     hex: "#150578",
    //     bind: "light"
    // }
];

let themeModes = [
    {
        name: "light",
        type: "light",
        text: {
            hex: "#000000",
            tailwind: "black"
        },
        uicolor: {
            name: "black",
            hex: "#262626",
            tailwind: "neutral-800"
        },
        background: {
            hex: "#f5f5f5",
            tailwind: "neutral-100"
        },
        primary: {
            hex: "#e5e5e5",
            tailwind: "neutral-200"
        },
        secondary: {
            hex: "#a3a3a3",
            tailwind: "neutral-400"
        },
    },
    {
        name: "dark",
        type: "dark",
        text: {
            hex: "#ffffff",
            tailwind: "white"
        },
        uicolor: {
            name: "white",
            hex: "#e5e5e5",
            tailwind: "neutral-200"
        },
        background: {
            hex: "#171717",
            tailwind: "neutral-900"
        },
        primary: {
            hex: "#262626",
            tailwind: "neutral-800"
        },
        secondary: {
            hex: "#525252",
            tailwind: "neutral-600"
        },
    }, 
    {
        name: "oled",
        type: "dark",
        text: {
            hex: "#ffffff",
            tailwind: "white"
        },
        uicolor: {
            name: "white",
            hex: "#f5f5f5",
            tailwind: "neutral-100"
        },
        background: {
            hex: "#000000",
            tailwind: "black"
        },
        primary: {
            hex: "#171717",
            tailwind: "neutral-900"
        },
        secondary: {
            hex: "#404040",
            tailwind: "neutral-700"
        },
    },
    /*{
        name: "tali",
        type: "light",
        text: {
            hex: "#000000",
            tailwind: "black"
        },
        uicolor: {
            name: "black",
            hex: "#262626"
        },
        background: {
            hex: "#adcc7c"
        },
        primary: {
            hex: "#9fc470"
        },
        secondary: {
            hex: "#87ad57"
        },
    },
    {
        name: "blue",
        type: "dark",
        text: {
            hex: "#ffffff",
            tailwind: "white"
        },
        uicolor: {
            name: "white",
            hex: "#f5f5f5",
            tailwind: "neutral-100"
        },
        background: {
            hex: "#4651e3",
            tailwind: "black"
        },
        primary: {
            hex: "#f55151",
            tailwind: "neutral-900"
        },
        secondary: {
            hex: "#404040",
            tailwind: "neutral-700"
        },
    },*/
]

addRgbAndCss();

const getThemeData = (themColorHex = "#16a34a", themeModeName = "light") => {
    let color = themeColors.filter(c => c.hex == themColorHex)[0];
    let mode = themeModes.filter(c => c.name == themeModeName)[0];
    if (!color) color = (mode ? mode.uicolor : themeModes[0].uicolor);
    if (!mode) mode = themeModes[0];
    //if (!color || !mode) return {color: (mode ? mode.uicolor : themeModes["light"].uicolor), mode: (mode ? mode : themeModes["light"])}; // sets theme 
    return { color, mode };
}


function addRgbAndCss() {

    // Colors
    themeColors = themeColors.map((color, i) => {
        let rgb = hexToRgb(color.hex);
        color = { ...color, ...rgb };
        color.css = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)`;
        return color;
    })


    themeModes = themeModes.map((mode, i) => {
        for (let par in mode) {
            if (!["name", "type"].includes(par)) {
                let rgb = hexToRgb(mode[par].hex);
                mode[par] = { ...mode[par], ...rgb };
                mode[par].css = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)`;
            }
        }
        return mode;
    })

}

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}







export { getThemeData, themeColors, themeModes }