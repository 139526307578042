import React from 'react';

function ActionBanner({ title, html }) {
    return (
        <div className='w-full p-4 h-24 flex justify-center items-center text-center font-fancy text-4xl lg:text-5xl font-medium text-theme-mode-text'>
            {title || html}
        </div>
    );
}

export default ActionBanner;