// icons
import { IoIosSearch } from "react-icons/io";
import { BsXLg } from "react-icons/bs";



function Search({ value, onChange, placeholder = "Search" }) {
    return (
        <div className='w-full px-3.5 py-2.5 rounded-full text-base font-bold text-theme-color bg-theme-color-02 flex flex-row justify-start items-center '>
            <div className='mr-4'>
                <IoIosSearch size="20" />
            </div>
            <input type="text" placeholder={placeholder} onChange={e => onChange(e?.target?.value || "")} value={value} className='w-full bg-transparent outline-none border-0 text-theme-mode-text placeholder-theme-color-05' />
            {value && value.length > 0 && <div className="p-1" onClick={() => onChange("")}>
                <BsXLg size="15"/>
            </div>}
        </div>
    );
}

export default Search;