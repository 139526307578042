import { useContext } from 'react';

import { PageContext } from '../PageContextProvider';

import {IoIosArrowDown} from "react-icons/io"

function SmallDivider({ name, children, expandable, expanded, onClick }) {
    const { theme } = useContext(PageContext);
    return (
        <div className='flex flex-col justify-start items-center w-full pb-4'>
            <div onClick={onClick} className='flex justify-between items-center w-full text-theme-mode-text font-extrabold text-sm'>
                <p>{name}</p>
                {expandable && <div className={"mr-1.5 " + (expanded ? "untwist" : "-twist-90")}>
                    <IoIosArrowDown color={theme?.mode.uicolor.hex || "#000"} size="14" />
                </div>}
            </div>
            {!expandable || expanded ? children : ""}
        </div>
    );
}

export default SmallDivider;