import React, { useContext } from 'react';

import { PageContext } from '.././components/PageContextProvider.jsx';

import { TailSpin } from 'react-loading-icons'

import { IconContext } from 'react-icons/lib';



// Functions
import hexToRgb from '.././functions/hex2rgb';
import getTextColor from '.././functions/getTextColor';
import getTransparentColorCss from '.././functions/getTransparentColor';

export const Button = ({ children, disabled = false, onClick, fill, loading, noAnimation, center, color }) => { // fill (fills the entire page)
    var pageContext = useContext(PageContext);
    if (!pageContext) pageContext = {};
    const { theme } = pageContext;

    if (!color) return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={`
            ${(fill ? "w-full" : "w-fit")} px-3 h-9 rounded-lg text-sm font-semibold m-0 transition-all duration-150 flex justify-start items-center 
            ${disabled && "bg-theme-color-05 text-theme-mode-background"}
            ${!disabled && "bg-theme-color text-theme-mode-background hover:bg-theme-color-08 focus:border-theme-color focus:outline-2 focus:outline-theme-color"}
            ${!noAnimation && " clickable "}
            ${center && " text-center justify-center "}
        `}
        >
            {loading ? (<TailSpin height="18" strokeWidth="3" stroke={theme ? theme.mode.background.hex : "#fff"} />) : children}
        </button>
    )



    // when custom color
    const textColor = getTextColor(hexToRgb(color));

    return (
        <IconContext.Provider value={{ color: textColor }}>
            <button style={{ color: textColor, backgroundColor: color }}
                onClick={onClick}
                disabled={disabled}
                className={`
            ${(fill ? "w-full" : "w-fit")} px-3 h-9 rounded-lg text-sm font-semibold m-0 transition-all duration-150 flex justify-start items-center 
            ${disabled && "bg-theme-color-05 text-theme-mode-background"}
            ${!disabled && "bg-theme-color text-theme-mode-background hover:bg-theme-color-08 focus:border-theme-color focus:outline-2 focus:outline-theme-color"}
            ${!noAnimation && " clickable "}
            ${center && " text-center justify-center "}
        `}
            >
                {loading ? (<TailSpin height="18" strokeWidth="3" stroke={theme ? theme.mode.background.hex : "#fff"} />) : children}
            </button>
        </IconContext.Provider>
    );
}



export const SecondaryButton = ({ children, disabled = false, onClick, fill, loading, noAnimation, center, color }) => {
    var pageContext = useContext(PageContext);
    if (!pageContext) pageContext = {};
    const { theme } = pageContext;
    if (!color) return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={`${(fill ? "w-full" : "w-fit")} px-3 h-9 rounded-lg text-sm font-semibold transition-all duration-150 flex justify-start items-center 
                    ${disabled ? "text-theme-color-05" : "bg-theme-color-02 text-theme-color hover:underline focus:border-theme-color focus:outline-2 focus:outline-theme-color"}
                    ${!noAnimation && " clickable "}
                    ${center && " text-center justify-center "}
`}
        >
            {loading ? (<TailSpin height="18" strokeWidth="3" stroke={theme ? theme.color.hex : "#000"} />) : children}
        </button>
    );

    const transparentColor = getTransparentColorCss(color);

    return (
        <button
            disabled={disabled}
            onClick={onClick}
            style={{ backgroundColor: transparentColor, color: color }}
            className={`${(fill ? "w-full" : "w-fit")} px-3 h-9 rounded-lg text-sm font-semibold transition-all duration-150 flex justify-start items-center                     ${!noAnimation && " clickable "}
                    ${center && " text-center justify-center "}
`}
        >
            {loading ? (<TailSpin height="18" strokeWidth="3" stroke={theme ? theme.color.hex : "#000"} />) : children}
        </button>
    )
}

export const TertiaryButton = ({ children, disabled = false, onClick, fill, loading, center }) => {
    var pageContext = useContext(PageContext);
    if (!pageContext) pageContext = {};
    const { theme } = pageContext;
    return (
        <button
            disabled={disabled}
            className={`${(fill ? "w-full" : "w-fit")} px-3 h-9 rounded-lg text-sm  font-semibold transition-all duration-150 flex justify-start items-center  
          ${disabled && "text-theme-color-05"}
          ${!disabled && "text-theme-color hover:underline focus:border-theme-color-02 focus:outline-2 focus:outline-theme-color-02"}
          ${center && " text-center justify-center "}
`}
            onClick={onClick}
        >
            {loading ? (<TailSpin height="18" strokeWidth="3" stroke={theme ? theme.color.hex : "#000"} />) : children}
        </button>
    );
}




export const PlusButton = ({ onClick = () => { }, noAnimation }) => {
    var pageContext = useContext(PageContext);
    if (!pageContext) pageContext = {};
    return (
        <div onClick={onClick} className={'border-2 border-theme-mode-background h-12 w-12 p-2 flex justify-center items-center rounded-full bg-theme-color relative ' + (!noAnimation ? "clickable" : "")}>
            <div className='p-0.5 bg-theme-mode-background rounded-full absolute w-6' />
            <div className='p-0.5 bg-theme-mode-background rounded-full absolute w-6 rotate-90' />
        </div>
    )
}




