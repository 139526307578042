import React, { useContext } from 'react';
import TailSpin from 'react-loading-icons/dist/esm/components/tail-spin.js';
import { getProfilePictureUrl } from '../../functions/storageHelper.js';

import { PageContext } from '../PageContextProvider.jsx';

function ProfilePicture({ size = "md", loading }) { // size (sm, md, lg)
    const { profile, theme, user } = useContext(PageContext);

    const url = null

    return (
        <div className={`bg-theme-color rounded-full flex justify-center items-center 
        ${size == "sm" ? "w-9 h-9 text-sm" : size == "lg" ? "w-28 h-28 text-4xl" : "w-20 h-20 text-2xl"}
        `}>
            <div className={`bg-theme-mode-background rounded-full 
            ${size == "sm" ? "w-7 h-7" : size == "lg" ? "w-24 h-24" : "w-16 h-16"}
            `}>
                <div className='w-full h-full bg-theme-color-02 rounded-full flex justify-center items-center'>
                    {loading ?
                        <TailSpin height="20" strokeWidth="4" stroke={theme ? theme.color.hex : "#ffffff"} />
                        :
                        url ?
                            <img src={url} />
                            :
                            <p className='text-theme-color font-semibold'>{profile && profile.username ? profile.username[0].toUpperCase() : "#"}</p>
                    }
                </div>
            </div>
        </div>
    );
}

/*
<div className={`text-theme-color bg-theme-color-02 border-4 border-theme-color rounded-full w-9 h-9 flex flex-row justify-center items-center select-none`}>
                <b>{profile && profile.username ? profile.username[0].toUpperCase() : "#"}</b>
            </div>
*/

export default ProfilePicture;