import { supabase } from ".././client"

export async function uploadProfilePicture(image, uid) {
    return await supabase
        .storage
        .from('profles')
        .upload(`pictures/${uid}.png`, image, {
            cacheControl: '3600',
            upsert: true
        })
}

export function getProfilePictureUrl(uid) {
    const { data } = supabase
        .storage
        .from('profiles')
        .getPublicUrl(`pictures/${uid}.png`)

    return data
}