import { useState } from "react";

import { SecondaryButton } from "../Buttons.jsx"

import { RiArrowDropDownLine } from "react-icons/ri";

import Style from "style-it";

const Select = ({ value, innerElement, setValue = val => { }, onChange = val => { }, stayOpen, children, dropdownAlign }) => {
  const [dropdownOpened, setDropdownOpened] = useState(false);

  function handleChange(value) {
    if (!stayOpen) setDropdownOpened(false);
    setValue(value);
    onChange(value)
  }
  const selectedChild = getSelectedChild()


  function getSelectedChild() {
    const child = (Array.isArray(children) ? children.filter(child => child.props.selected)[0] : children)


    return (
      <div className="flex items-center">
        {child?.props?.icon && <div className="mr-2 bg-theme-mode-background rounded">
          <div className="rounded w-full h-full bg-theme-color-02">
            {child?.props?.icon}
          </div>
        </div>}
        <div className="ml-1">
          {child?.props?.children}
        </div>
      </div>
    )
  }


  return (
    <div>
      <SecondaryButton onClick={() => setDropdownOpened(!dropdownOpened)}>
        <div className="flex flex-row justify-start">
          <div className="whitespace-nowrap overflow-x-hidden">
            {innerElement ? innerElement : selectedChild ? selectedChild : value}
          </div>
          <div className="ml-2 flex items-center">
            <RiArrowDropDownLine size="20" />
          </div>
        </div>
      </SecondaryButton>
      {dropdownOpened &&
        <div className="absolute top-0 bottom-0 right-0 left-0" onClick={() => setDropdownOpened(!dropdownOpened)}></div>
      }
      {dropdownOpened &&
        <div className={`flex z-50 flex-row  ${dropdownAlign == "center" ? "justify-center" : dropdownAlign == "left" ? "justify-end" : "justify-start"}`}>
          <div className="bg-theme-mode-background rounded-xl absolute w-fit mt-1">
            <div className="flex flex-col w-full bg-theme-color-02 px-1.5 py-1 rounded-xl overflow-y-scroll h-fit max-h-64 scrollbar-hide">
              {children.map((child, i) => {
                return (
                  <button key={i} type="button" onClick={() => handleChange(child.props.value)}>
                    {child}
                  </button>
                )
              })}
            </div>
          </div>
        </div>
      }

    </div>
  );
}

export const SelectOption = ({ children, value, selected, customBgColor, icon }) => {
  const style = {
    backgroundColor: (selected ? customBgColor : ""),
  }

  return (
    <Style>
      {`
        .cutom-hover-bg-color:hover {
          background-color: ${customBgColor};
        }
      `}


      <div style={style} className={`${selected ? `${customBgColor ? "" : "bg-theme-color"} text-theme-mode-background` : "text-theme-color"} ${customBgColor ? "" : "hover:bg-theme-color"} 
      cutom-hover-bg-color hover:text-theme-mode-background w-full text-left whitespace-nowrap h-9 rounded-lg pl-1 pr-6 my-0.5 font-semibold text-sm flex flex-row justify-start items-center transition-all duration-150`} value={value}>
        {icon && <div className="mr-2 bg-theme-mode-background rounded-sm">
          <div className="rounded-sm p-1 w-full h-full bg-theme-color-02">
            {icon}
          </div>
        </div>}
        <div className="ml-1">
          {children}
        </div>
      </div>
    </Style>
  );
}

export default Select;