import { motion } from "framer-motion";

function TitleLabel({ title }) {
    return (
        <motion.div
            initial={{ scale: "90%" }}
            animate={{ scale: "100%" }}
            className='shrink w-fit p-2 h-10 bg-theme-color-02 rounded-lg flex flex-col justify-center items-center border-4 border-theme-color text-theme-color font-semibold text-lg font-title'>
            {title}
        </motion.div>
    );
}

export default TitleLabel;