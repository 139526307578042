import React, { useContext } from 'react';
import { PageContext } from './PageContextProvider';


import TitleLabel from './TitleLabel';

import { MdClose } from "react-icons/md";

import { motion } from "framer-motion"


const popUpAnimation =  {
    hidden: {
        y: "100vh"
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.18,
        } 
    },
    exit: {
        y: "100vh"
    }
}

function PopUp({ children, title }) {
    const { setPopUp } = useContext(PageContext);
    return (
        <motion.div
        variants={popUpAnimation}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="relative bottom-0 w-full h-full bg-theme-mode-background rounded-t-3xl border-8 border-b-0 border-theme-color flex flex-col z-40">
            <div className='flex justify-between p-2'>
                <div className='basis-1/2 felx justify-start items-center'>
                    <div className='p-0.5 w-fit clickable' onClick={() => setPopUp(false)}>
                        <MdClose />
                    </div>
                </div>
                {title ?
                    <div className='text-xl text-theme-color font-bold' onClick={() => setPopUp({ title: "2nd popup" })}>
                        {title.toUpperCase()}
                    </div>
                    : ""}
                <div className='basis-1/2'>

                </div>
            </div>
            <div className='overflow-y-scroll scrollbar-hide pt-4 px-4 h-full w-full max-w-2xl'>
                {children}
            </div>

        </motion.div>
    );
}

export default PopUp;