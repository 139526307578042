import { useState, useEffect, useContext } from 'react';

import { supabase } from "../../client";

import { PageContext } from '../../components/PageContextProvider';

// icons
import { BsClock, BsFillPersonPlusFill, BsFillPersonXFill } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";


//Components 
import ContentArea from "../../components/ContentArea";
import FriendCard from './FriendCard';
import TailSpin from 'react-loading-icons/dist/esm/components/tail-spin';
import Search from "../../components/Search";
import TryAgainView from '../TryAgainView';
import SearchFriendsView from './SearchFriendsView';
import SmallDivider from '../../components/pageDividers/SmallDivider';

function FriendsView({ }) {

    const { setPrompt, theme } = useContext(PageContext)

    const [friends, setFriends] = useState(null);
    const [requests, setRequests] = useState(null);
    const [expandRequests, setExpandRequests] = useState(true);
    const [search, setSearch] = useState("");
    const [error, setError] = useState();

    useEffect(() => {

        fetchData();

    }, [])

    function fetchData() {
        getFriends();
        getRequests();
    }

    async function getFriends() {
        let { data, error } = await supabase
            .rpc('getfriends')

        if (!error) setFriends(data)


    }

    async function getRequests() {
        let { data, error } = await supabase
            .rpc('getfriendrequests')

        if (!error) setRequests(data)

    }


    async function befriend({ id }) {
        let { data, error } = await supabase
            .rpc('befriend', {
                friendid: id
            })

        if (!error) fetchData()
    }


    async function handleRemoveFriend({ id, username }) {

        const value = (
            <div className="flex justify-start items-center">
                <BsFillPersonXFill size="20" color="#f73e3e" /> <div className="ml-2">Remove <span className="font-semibold">@{username}</span>?</div>
            </div>
        )

        setPrompt({ value, onApprove: () => rem(id), buttonValues: { approve: "Remove" } });

        async function rem(id) {
            let { data, error } = await supabase
                .rpc('removefriend', {
                    friendid: id
                })



            if (!error) getFriends();
        }

    }

    async function handleIgnoreRequest({ id, username }) {
        const value = (
            <div className="flex justify-start items-center">
                <div className="ml-2">Ignore <span className="font-semibold">@{username}</span>?</div>
            </div>
        )

        setPrompt({ value, onApprove: () => rem(id), buttonValues: { approve: "Remove" } });

        async function rem(id) {
            let { data, error } = await supabase
                .rpc('removefriend', {
                    friendid: id
                })



            if (!error) getRequests();
        }
    }

    return (
        <ContentArea>
            <Search value={search} onChange={setSearch} placeholder="Search profiles" />
            {search.length > 0 ?
                <SearchFriendsView search={search} />
                :
                <div className='w-full'>
                    {friends && requests ? <div className='pb-20 pt-4'>
                        {requests && requests.length > 0 && <SmallDivider expandable expanded={expandRequests} onClick={() => setExpandRequests(!expandRequests)} name={`REQUESTS (${requests.length})`}>
                            {requests && requests[0] && expandRequests && <div className='mb-4 w-full flex flex-col justify-start items-center'>
                                {requests.map((request, i) => <FriendCard onAccept={befriend} onIgnore={handleIgnoreRequest} key={i} {...request} />)}
                            </div>}
                        </SmallDivider>}
                        <SmallDivider name={`MY FRIENDS ${friends ? `(${friends.length})` : ""}`}>
                            {friends && friends[0] && friends.map((friend, i) => <FriendCard onRemove={handleRemoveFriend} key={i} {...friend} />)}
                        </SmallDivider>
                    </div>
                        :
                        error ?
                            <TryAgainView tryAgain={() => { fetchData() }} />
                            :
                            <div className='w-full flex justify-center items-center pt-10'><TailSpin height="30" strokeWidth="3" stroke={theme ? theme.color.hex : "#000"} /></div>
                    }
                </div>}
        </ContentArea>
    );
}




export default FriendsView;