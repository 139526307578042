import { motion } from 'framer-motion';

function DragIcon({ icon, position = "left" }) {
    if (!icon) return ""


    return (
        <div className={'px-4 absolute top-0 bottom-0 flex flex-col justify-center items-center ' + (position !== "left" ? "left-0" : "right-0")}>
            <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.2, type: 'spring' }}>
                <div className='bg-theme-mode-primary h-11 w-11 rounded-full flex justify-center items-center'>{icon}</div>
            </motion.div>
        </div>
    )
}

export default DragIcon;