// Framer Motion
import { LayoutGroup } from "framer-motion";

// Components
import MiniInfo from "../../components/badge/MiniInfo";
import NoteCard from "../../components/cards/NoteCard";

//Icons
import { FaDove, FaPray } from "react-icons/fa"
import { BsPinAngleFill, BsPinAngle } from "react-icons/bs";


function PrayersCol({ prayers, pinnedPrayers, onAnswered, onPin, onClick, color }) {
    const PrayerCardDragOptions = {
        right: {
            icon: (<FaDove size="20" color={"#7bb2e8"} />),
            altIcon: (<FaDove size="20" color={"#d47c5f"} />),
            onDragged: onAnswered
        },
        left: {
            icon: (<BsPinAngleFill size="20" color="#7bb2e8" />),
            altIcon: (<BsPinAngle size="20" color={"#d47c5f"} />),
            onDragged: onPin
        },
    }

    return (
        <div className="flex flex-col">
            {pinnedPrayers?.length > 0 && <div className="mb-8 flex flex-col">
                <MiniInfo icon={<BsPinAngleFill color={color} size={"18"} />} text="Pinned" color={color} />
                <LayoutGroup>
                    {pinnedPrayers.map(prayer => {
                        const iconBadges = [
                            (prayer.answered ? <FaDove key="dove" /> : null),
                            (prayer.pinned ? <BsPinAngleFill key="pin" /> : null)
                        ]
                        const altDragIcons = { right: prayer.answered, left: prayer.pinned }


                        return (<NoteCard readMore={true} semiTransparent={!prayer.answered} iconBadges={iconBadges} altDragIcons={altDragIcons} noAnimate={prayer.noAnimate} dragOptions={PrayerCardDragOptions} key={prayer.id} onClick={onClick} color={color} {...prayer} />)
                    })}
                </LayoutGroup>

            </div>}
            <div>
                {prayers?.length > 0 && <>
                    <MiniInfo icon={<FaPray color={color} size={"18"} />} text="Prayers" color={color} />
                    <LayoutGroup>
                        {prayers.map(prayer => {
                            const iconBadges = [
                                (prayer.answered ? <FaDove key="dove" /> : null),
                                (prayer.pinned ? <BsPinAngleFill key="pin" /> : null)
                            ]
                            const altDragIcons = { right: prayer.answered, left: prayer.pinned }


                            return (<NoteCard readMore={true} semiTransparent={!prayer.answered} iconBadges={iconBadges} altDragIcons={altDragIcons} noAnimate={prayer.noAnimate} dragOptions={PrayerCardDragOptions} key={prayer.id} onClick={onClick} color={color} {...prayer} />)
                        })}
                    </LayoutGroup>
                </>
                }
            </div>

        </div>
    );
}

export default PrayersCol;