import React, { useState } from 'react';
import { useEffect } from 'react';

import { supabase } from '../client';

import { motion, AnimatePresence } from "framer-motion"

import LargeTextInput from '../components/form/LargeTextInput';
import Form, { FormRow } from "../components/form/Form"
import { Button } from '../components/Buttons';
import BannerBox from "../components/banners/BannerBox"
import { ThemePopUp } from "./sub/SettingsView.jsx"


// icons
import { CgClose } from "react-icons/cg"
import { HiIdentification, HiUser } from "react-icons/hi"

const states = [
    {
        name: "user_data",
        display_name: "Profile setup"
    },
    {
        name: "theme",
        display_name: "Theme setup"
    },
]

const invalidAnimation = {
    hidden: {
        y: "-7px",
        opacity: 0
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
        }
    },
    exit: {
        y: "-7px",
        opacity: 0
    }
}

const pageAnimation = {
    hidden: {
        x: "100vw",
        opacity: 0
    },
    visible: {
        x: "0",
        opacity: 1,
        transition: {
            duration: 0.2,
        }
    },
    exit: {
        x: "-100vw",
        opacity: 0
    }
}


// 10 + 8 + 12 + 8 + 3 + 8 + 7 + 8 + 11 + 3 / 10



function ProfileSetupView({ getProfile, user }) {
    const [state, setState] = useState(states[0]);

    return (
        <div>


            <AnimatePresence
                initial={false}
                exitBeforeEnter={true}>
                {state.name == "user_data" &&

                    <motion.div
                        variants={pageAnimation}
                        initial="hidden"
                        animate="visible"
                        exit="exit">
                        <div className='z-40 absolute top-0 bottom-0 right-0 left-0 bg-theme-mode-background flex justify-center'>
                            <div className='max-w-2xl w-full px-4 pt-4'>
                                <UserDataSetup user={user} setState={setState} />
                            </div>
                        </div>
                    </motion.div>

                }
            </AnimatePresence>

            <AnimatePresence
                initial={false}
                exitBeforeEnter={true}>
                {state.name == "theme" &&
                    <motion.div
                        variants={pageAnimation}
                        initial="hidden"
                        animate="visible"
                        exit="exit">
                        <div className='z-40 absolute top-0 bottom-0 right-0 left-0 bg-theme-mode-background flex justify-center'>
                            <div className='max-w-2xl w-full px-4 pt-4'>
                                <ThemeSetup getProfile={getProfile} />
                            </div>
                        </div>
                    </motion.div>

                }
            </AnimatePresence>


        </div>
    );
}

function UserDataSetup({ user, setState }) {
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [showError, setShowError] = useState("");

    const [loading, setLoading] = useState(false);

    const [invalid, setInvalid] = useState(true);
    const [invalidMessage, setInvalidMessage] = useState();

    useEffect(() => {
        

        let invalid = false;

    



        let invalidMessage = ""

        if (username && username.length < 3) invalidMessage = "Username must be 3 or more characters."
        if (username && username.length >= 20) invalidMessage = "Username cannot be longer than 20 characters."


        if (name && !/^[ -~]{3,20}$/.test(name)) invalidMessage = "Name must be alphanumeric."
        if (name && name.length < 3) invalidMessage = "Name must be 3 or more characters."
        if (name && name.length >= 20) invalidMessage = "Name cannot be longer than 20 characters."

        if (invalidMessage != "") invalid = true;

        if (name.length <= 0 || username.length <= 0) invalid = true;

        setInvalid(invalid);
        setInvalidMessage(invalidMessage)
    }, [name, username])

    function handleUsernameChange(str) {
        str = str.replace("@", "");
        str = str.replace(/[\s-]+/g, '_').toLowerCase();
        const valid = /^(|[a-z][a-z0-9._]*)$/.test(str);
        if (valid) setUsername("@" + str)
    }

    async function next(name, username) {
        setShowError("");

        setLoading(true);

        username = username.replace("@", "");



        if (user) {
            const { data: hasUsernameArray, usernameError } = await supabase
                .from('profiles')
                .select("username")
                .eq('username', username)


            if (usernameError || !hasUsernameArray) return setShowError("Something went wrong :(")
            if (hasUsernameArray[0]) setShowError("This username already exists")
            else {
                const { data, error } = await supabase
                    .from('profiles')
                    .update({ username, name })
                    .eq('user_id', user.id)

                if (error) setShowError("Something went wrong :(")

                if (!error) setState(states[1]);
            }





        } else setShowError("Something went wrong :(");

    }

    useEffect(() => {
        if (showError && showError.length > 0) setLoading(false);
    }, [showError])

    return (
        <div>
            <BannerBox title="WELCOME">
                Good to see you.<br />
                Please create your profile.
            </BannerBox>
            <div>
                <Form buttonText="Next" loading={loading} error={showError} invalid={invalid} invalidMessage={invalidMessage} onSubmit={() => next(name, username)}>
                    <FormRow>
                        <LargeTextInput icon={<HiUser size="20" />} placeholder="Name" value={name} onChange={(name) => setName(name)} />
                    </FormRow>
                    <FormRow>
                        <LargeTextInput icon={<HiIdentification size="20" />} placeholder="username" value={username} onChange={(name) => handleUsernameChange(name.trim())} />
                    </FormRow>
                </Form>
            </div>
        </div>
    );
}

function ThemeSetup({ getProfile }) {
    const [loading, setLoading] = useState(false);
    return (
        <div>
            <BannerBox title="THEME">
                Select a theme to give the app a personal touch.
            </BannerBox>
            <ThemePopUp />
            <div className='flex justify-end mt-4'>
                <Button disabled={loading} loading={loading} onClick={() => { setLoading(true); getProfile() }}>
                    Finish
                </Button>
            </div>
        </div>
    )
}




export default ProfileSetupView;