import { useEffect, useState } from 'react';

import { Button, TertiaryButton } from "./Buttons.jsx";


function Prompt({ children, setPrompt, onApprove = () => { }, buttonValues = {} }) { // button values {approve: "Okay", cancel: "Cancel"}

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => setLoaded(true), 50);
    }, [])

    function handleApprove() {
        onApprove();
        setPrompt(null);
    }

    return (
        <div className='absolute top-0 bottom-0 right-0 left-0 z-[51] flex justify-center items-center bg-neutral-700/[0.35]' onClick={() => setPrompt(null)}>
            <div className={'w-full max-w-md m-8 bg-theme-mode-primary border-4 border-theme-color shadow-md rounded-xl p-2 flex flex-col z-50 ' + (loaded ? "animate-in " : "animate-out ")}>
                <div className='w-full text-lg p-1.5 px-3 mb-1 text-left z-50'>
                    {children ? children : "Are you sure?"}
                </div>
                {buttonValues !== null && <div className='w-full flex flex-row justify-end p-1'>
                    <TertiaryButton onClick={() => setPrompt(null)}>{buttonValues.cancel ? buttonValues.cancel : "Cancel"}</TertiaryButton>
                    <Button onClick={() => handleApprove()}>{buttonValues.approve ? buttonValues.approve : "Okay"}</Button>
                </div>}
            </div>
        </div>
    );
}

export default Prompt;