const colorPalettes = [
    {
        name: "Default",
        colors: [
            "#f43f5e",
            "#ec4899",
            "#a855f7",
            "#3b82f6",
            "#14b8a6",
            "#16a34a",
            "#84cc16",
            "#facc15",
            "#f59e0b",
            "#f96d16",
        ]
    },
    {
        name: "Normal",
        colors: ["#ff595e", "#ff924c", "#ffca3a", "#c5ca30", "#8ac926", "#36949d", "#1982c4", "#4267ac", "#565aa0", "#6a4c93"]
    },
    {
        name: "Pair",
        colors: ["#669900", "#99cc33", "#ccee66", "#006699", "#3399cc", "#990066", "#cc3399", "#ff6600", "#ff9900", "#ffcc00"]
    },
    {
        name: "Light",
        colors: ["#ff61ab", "#ff6176", "#ff8161", "#ffb561", "#ffea62", "#dfff61", "#abff61", "#76ff61", "#61ff81", "#61ffb5"]
    },
    {
        name: "Opaque",
        colors: ["#264653", "#287271", "#2a9d8f", "#8ab17d", "#babb74", "#e9c46a", "#efb366", "#f4a261", "#ee8959", "#e76f51"]
    }
];


// default
// [ 
//     "#f43f5e",
//     "#a855f7",
//     "#3b82f6",
//     "#14b8a6",
//     "#16a34a",
//     "#f59e0b",
// ]

// {
//     name: "",
//     colors: [
//         "#ec4899",
//         "#a855f7",
//         "#3b82f6",
//         "#0ea5e9",
//         "#14b8a6",
//         "#16a34a",
//         "#84cc16",
//         "#facc15",
//         "#f97316",
//         "#ef4444"
//     ]
// },

export default colorPalettes