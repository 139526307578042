import { useState, useEffect, useContext } from "react";

import { PageContext } from '../../components/PageContextProvider';

// components 
import BannerBox from "../../components/banners/BannerBox"
import { Button } from "../../components/Buttons";
import ContentArea from "../../components/ContentArea";
import TopBar from "../../components/nav/TopBar";


// Icons
import TailSpin from "react-loading-icons/dist/esm/components/tail-spin";
import { BsFilePerson, BsLightningChargeFill, BsPeopleFill } from "react-icons/bs"
import { FaHeart, FaPray } from "react-icons/fa"

// View
import SessionView from "./SessionView"

function SelectSessionView(props) {

    const { theme } = useContext(PageContext)

    const [loading, setLoading] = useState(false);

    const [session, setSession] = useState(null);

    return (
        <div>
            {!session && <>
                <TopBar avatar wordmark="PRAYER PROJECT" clickables={(loading ? [<TailSpin height="20" strokeWidth="4" stroke={theme ? theme.color.hex : "#000"} />] : [])}>

                </TopBar>
                <ContentArea>
                    <BannerBox title={(
                        <div className="flex justify-center w-full ">
                            <BsLightningChargeFill />
                            <div className="ml-4">SESSIONS</div>
                        </div>
                    )}>
                        Get practical and start a prayer session.
                    </BannerBox>
                    <div className="mt-10">
                        <StartSessionRow onClick={setSession} limits={{ gratitude: 4, prayer: 3, people: 5 }} isQuickStart />
                        <div className="w-full flex items-center justify-center rounded-3xl p-3 py-0.5 bg-theme-mode-primary mt-10">
                            Prefabs coming soon!
                        </div>
                        {/* <div className="w-full flex flex-wrap items-center  justify-between rounded-3xl p-3 py-0.5 bg-theme-mode-primary mt-10">
                        <StartSessionRow onClick={() => { }} limits={{ prayer: 1, gratitude: 3, people: 5 }} name="skurr" />
                        <StartSessionRow onClick={() => { }} limits={{ prayer: 1, gratitude: 3, people: 5 }} name="skurr" />
                        <StartSessionRow onClick={() => { }} limits={{ prayer: 1, gratitude: 3, people: 5 }} name="skurr" />
                        <StartSessionRow onClick={() => { }} limits={{ prayer: 1, gratitude: 3, people: 5 }} name="skurr" />
                        <StartSessionRow onClick={() => { }} limits={{ prayer: 1, gratitude: 3, people: 5 }} name="skurr" />
                    </div> */}
                    </div>

                </ContentArea>
            </>}


            {session && <SessionView limits={session} closeSession={() => setSession(null)} />}

        </div>
    );
}


function StartSessionRow({ onClick, limits = {}, name, isQuickStart }) { // limits = {gratitude, prayer, people}

    const { theme } = useContext(PageContext)

    return (
        <div className={"clickable w-full rounded-2xl p-4 my-2.5 " + (isQuickStart ? " theme-gradient" : " sm:max-w-xs max-w-full bg-theme-color")} onClick={() => onClick([limits.gratitude || 0, limits.prayer || 0, limits.people || 0])}>
            <div className="text-white font-extrabold text-2xl mb-4">
                {isQuickStart ?
                    "Quick start"
                    :
                    name
                }
            </div>
            <div className="flex items-center justify-around">
                {limits.gratitude && limits.gratitude > 0 ? <SessionInfo icon={<FaHeart color={"#ffffff"} size={20} />} amount={limits.gratitude} /> : ""}
                {limits.prayer && limits.prayer > 0 ? <SessionInfo icon={<FaPray color={"#ffffff"} size={20} />} amount={limits.prayer} /> : ""}
                {limits.people && limits.people > 0 ? <SessionInfo icon={<BsFilePerson color={"#ffffff"} size={20} />} amount={limits.people} /> : ""}
            </div>
        </div>
    )





}

export function SessionInfo({ icon, amount, themeColored, textColored }) {
    return (
        <div className="flex justify-center items-center">
            {icon}
            <div className={"text-xl ml-2 font-semibold " + (themeColored ? " text-theme-color " : textColored ? " text-theme-mode-text " : " text-white ")}>
                {amount}
            </div>
        </div>
    )
}

export default SelectSessionView;
