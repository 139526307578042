import { useState, useEffect, useContext } from "react";

import { supabase } from "../client";


import { motion, AnimatePresence, AnimateSharedLayout, LayoutGroup } from "framer-motion";


// Components 
import ActionBanner from "../components/banners/ActionBanner";
import TopBar from '../components/nav/TopBar';
import ContentArea from '../components/ContentArea';
import { PlusButton } from "../components/Buttons";
import TryAgainView from "./TryAgainView";
import PeopleCard from "../components/cards/PeopleCard";
import MiniInfo from "../components/badge/MiniInfo";

// Functions 
import rng from "../functions/rng";

// Icons 
import { FaTrash, FaDove, FaStar, FaRegStar, FaPray, FaPrayingHands } from "react-icons/fa"
import { BsPinAngleFill, BsPinAngle, BsPlusLg, BsFillPersonFill, BsPeopleFill } from "react-icons/bs";
import TailSpin from "react-loading-icons/dist/esm/components/tail-spin";
import NoteCardPrompt from "../components/cards/NoteCardPrompt";


//Views
import AddPeopleView from "./People/AddPeopleView";
import EditPeopleView from "./People/EditPeopleView"
// People Prayer Views
import AddPeoplePrayerView from "./People/Prayers/AddPeoplePrayerView";
import EditPeoplePrayerView from "./People/Prayers/EditPeoplePrayerView";


import { PageContext } from '../components/PageContextProvider';


const PrefabPeopleToPrayFor = [
    "Mom",
    "Dad",
    "Friend",
    "Colleague",
    "Partner",
    "Brother",
    "Sister",
    "Grandma",
    "Grandpa",
    "Sibling"
]


const dummyPeople = [
    {
        id: 1,
        name: "Marcel",
        color: "#3e32a8"
    }
]


const miniButtonAnimation = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.2,
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.1,
        }
    }
}


function PeopleView({ }) {

    const PeopleCardDragOptions = {
        right: {
            icon: (<BsPlusLg size="20" color={"#29b35b"} />),
            altIcon: (<BsPlusLg size="20" color={"#29b35b"} />),
            onDragged: showAddPrayer
        },
        left: {
            icon: (<BsPinAngleFill size="20" color="#7bb2e8" />),
            altIcon: (<BsPinAngle size="20" color={"#d47c5f"} />),
            onDragged: onPin
        },
    }




    const { theme, setPopUp, setPrompt, user } = useContext(PageContext);


    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true)

    const [openAddMenu, setOpenAddMenu] = useState(false);
    const [randomPeoplePerson, setRandomPeoplePerson] = useState(getRandomPeoplePerson())


    const [pinnedPeople, setPinnedPeople] = useState([]);
    const [people, setPeople] = useState([]);




    const [focusedPerson, setFocusedPerson] = useState(null);



    useEffect(() => {

        // supabase
        //     .channel(`public:p_people:user_id=eq.'${user.id}'`)
        //     .on('postgres_changes', { event: '*', schema: 'public', table: 'p_people' }, payload => {
        //         getPinnedPeople();
        //         getPeople();
        //     })
        //     .subscribe()



        getPinnedPeople();
        getPeople();



    }, [])



    // DB

    // People
    async function getPeople() {
        setError(null);
        let { data, error } = await supabase
            .from('p_people')
            .select('*')
            .eq("pinned", false)
            .order("updated_at", { ascending: false })


        if (error) setError(error);
        else updatePeopleState(data, people)

        setLoading(false)
    }


    async function getPinnedPeople() {
        setError(null);
        let { data, error } = await supabase
            .from('p_people')
            .select('*')
            .eq('user_id', user.id)
            .eq("pinned", true)
            .order("updated_at", { ascending: false })


        if (error) setError(error);
        else updatePinnedPeopleState(data, pinnedPeople)

        setLoading(false)
    }


    function updatePinnedPeopleState(data, pinnedPeople) {
        data = data.map(peo => {
            if (pinnedPeople.filter(e => e.id === peo.id)[0]) peo.noAnimate = true;
            else peo.noAnimate = false;
            return peo;
        })
        setPinnedPeople(data);
    }


    function updatePeopleState(data, people) {
        data = data.map(peo => {
            if (people.filter(e => e.id === peo.id)[0]) peo.noAnimate = true;
            else peo.noAnimate = false;
            return peo;
        })
        setPeople(data);
    }







    // random Functions
    function getDataFromId(id) {
        return people.filter(e => e.id === id)[0] || pinnedPeople.filter(e => e.id === id)[0] || null;
    }


    function getRandomPeoplePerson() {
        return PrefabPeopleToPrayFor[rng(0, PrefabPeopleToPrayFor.length - 1)]
    }


    function handleAddPersonClick() {
        setOpenAddMenu(false);
        setPopUp({ title: "People", element: (<AddPeopleView onAdd={onPeopleAdd} />) })
    }

    function handleEditPersonClick(id) {
        setOpenAddMenu(false);
        const data = getDataFromId(id);
        setPopUp({ title: "People", element: <EditPeopleView data={data} onEdit={onEdit} /> })

        function onEdit() {
            setPopUp(false);
        }
    }




    // Events
    function onPeopleAdd() {
        setFocusedPerson(null);
        setPopUp(false);
        getPinnedPeople();
        getPeople();
    }


    function onPeopleDelete(id) {
        const data = getDataFromId(id);
        const value = (
            <div className="flex justify-start items-center">
                <FaTrash size="20" color="#f73e3e" /> <div className="ml-2">Delete <span className="font-semibold">{data.name}</span>?</div>
            </div>
        )



        setPrompt({ value, onApprove: () => deletePrayer(id), buttonValues: { approve: "Delete" } });


        async function deletePrayer(id) {
            setFocusedPerson(null)
            const { prayer_data, prayer_error } = await supabase
                .from('p_people_prayer')
                .delete()
                .eq('people_id', id)

            const { data, error } = await supabase
                .from('p_people')
                .delete()
                .eq('id', id)


            getPinnedPeople();
            getPeople();


        }
    }


    async function onPin(id) {
        setFocusedPerson(null);
        const data = getDataFromId(id);
        if (!data) return
        data.pinned = !data?.pinned;
        const newPeople = people.map(peo => {
            if (peo.id === data.id) return data;
            return peo;
        })
        updatePeopleState(newPeople, people);

        const { error } = await supabase
            .from('p_people')
            .update({ pinned: data.pinned })
            .eq('id', id)

        getPinnedPeople();
        getPeople();
    }

    function onFocus(id) {
        let focus = focusedPerson
        setFocusedPerson(null)
        setTimeout(() => setFocusedPerson((focus === id ? null : id)), 150);
    }







    // 
    /// 
    ////  PEOPLE PRAYERS
    ///
    //



    const [prayerObject, setPrayerObject] = useState({});

    const [prayerError, setPrayerError] = useState(null);


    const [showNoteCardPrompt, setShowNoteCardPrompt] = useState(false);


    useEffect(() => {

        // supabase
        //     .channel(`public:p_people_prayer:user_id=eq.'${user.id}'`)
        //     .on('postgres_changes', { event: '*', schema: 'public', table: 'p_people_prayer' }, async payload => {
        //         const people_id = payload?.new?.people_id;
        //         const prayers = await fetchPeoplePrayers(people_id);
        //         const newPrayerObject = { ...prayerObject, [people_id]: prayers };
        //         setPrayerObject(newPrayerObject);

        //     })
        //     .subscribe()





    }, [])


    async function getPeoplePrayers(people_id) {
        const person = getDataFromId(people_id);
        if (!person) return [];
        if (prayerObject[people_id]) {
            return addPersonColorToPrayer(prayerObject[people_id], person?.color);
        } else {
            let newPrayers = await fetchPeoplePrayers(people_id);
            if (!newPrayers || newPrayers.length < 1) return [];

            newPrayers = addPersonColorToPrayer(newPrayers, person?.color)

            const newPrayerObject = { ...prayerObject, [people_id]: newPrayers };
            setPrayerObject(newPrayerObject);
            return newPrayers;
        }
    }


    async function getNewPrayers(people_id) {
        const person = getDataFromId(people_id);
        if (!person) return [];
        let newPrayers = await fetchPeoplePrayers(people_id);
        if (!newPrayers || newPrayers.length < 1) return [];

        newPrayers = addPersonColorToPrayer(newPrayers, person?.color)

        const newPrayerObject = { ...prayerObject, [people_id]: newPrayers };
        setPrayerObject(newPrayerObject);
    }


    function addPersonColorToPrayer(prayers, color) {
        prayers?.map(p => {
            p.color = color;
            return p;
        });
        return prayers;
    }


    async function updateSpecificPrayerLocally(people_id, id, data) {
        const newPrayerObject = JSON.parse(JSON.stringify(prayerObject));
        newPrayerObject[people_id] = newPrayerObject?.[people_id]?.map(p => (p.id === id ? data : p));

        setPrayerObject(newPrayerObject);
    }


    // Events

    async function onPrayerAdd(people_id) {
        setPopUp(false);
        getNewPrayers(people_id);
    }

    async function onPrayerPin(people_id, id) {
        const data = getPeoplePrayerFromId(people_id, id);
        data.pinned = !data.pinned;

        updateSpecificPrayerLocally(people_id, id, data);


        const { error } = await supabase
            .from('p_people_prayer')
            .update({ pinned: data.pinned })
            .eq('id', id)

        getNewPrayers(people_id);
    }

    async function onPrayerAnswered(people_id, id) {
        const data = getPeoplePrayerFromId(people_id, id);
        data.answered = !data.answered;

        updateSpecificPrayerLocally(people_id, id, data);

        const { error } = await supabase
            .from('p_people_prayer')
            .update({ answered: data.answered })
            .eq('id', id)

        getNewPrayers(people_id);
    }

    async function onPrayerDelete(people_id, id) {
        const data = getPeoplePrayerFromId(people_id, id);
        const value = (
            <div className="flex justify-start items-center">
                <FaTrash size="20" color="#f73e3e" /> <div className="ml-2">Delete <span className="font-semibold">{data.title}</span>?</div>
            </div>
        )

        setShowNoteCardPrompt(null);

        setPrompt({ value, onApprove: () => deletePrayer(id), buttonValues: { approve: "Delete" } });


        async function deletePrayer(id) {
            const { data, error } = await supabase
                .from('p_people_prayer')
                .delete()
                .eq('id', id)

            getNewPrayers(people_id);
        }
    }




    // DB

    async function fetchPeoplePrayers(people_id) {
        setPrayerError(null);
        let { data, error } = await supabase
            .from('p_people_prayer')
            .select('*,id::text')
            .eq('user_id', user.id)
            .eq('people_id', people_id)
            .order("updated_at", { ascending: false })


        if (error) setPrayerError(people_id);



        return data;
    }


    function getPeoplePrayerFromId(people_id, id) {
        return prayerObject?.[people_id]?.filter(p => p.id === id)[0] || null;
    }


    // Views

    function showAddPrayer(people_id) {
        const data = getDataFromId(people_id);
        setPopUp({ title: "Add", element: <AddPeoplePrayerView person={data} onAdd={() => onPrayerAdd(people_id)} /> })
    }


    async function showPrayerEdit(people_id, id) {
        const data = getPeoplePrayerFromId(people_id, id);
        const person = getDataFromId(people_id);
        setPopUp({ title: "Edit", element: <EditPeoplePrayerView data={data} person={person} onEdit={() => setPopUp(null)} /> })
        setShowNoteCardPrompt(null);
    }










    function handleOnPrayerClick(people_id, id) {
        const data = getPeoplePrayerFromId(people_id, id);
        const person = getDataFromId(people_id);
        setShowNoteCardPrompt({ ...data, person })
    }


    return (
        <div>
            <TopBar avatar wordmark="PRAYER PROJECT" clickables={(loading ? [<TailSpin height="20" strokeWidth="4" stroke={theme ? theme.color.hex : "#000"} />] : [])}>

            </TopBar>
            {showNoteCardPrompt ? <NoteCardPrompt onEdit={() => showPrayerEdit(showNoteCardPrompt?.person?.id, showNoteCardPrompt?.id)} onDelete={() => onPrayerDelete(showNoteCardPrompt?.person?.id, showNoteCardPrompt?.id)} onPin={() => onPrayerPin(showNoteCardPrompt?.person?.id, showNoteCardPrompt?.id)} pinnable semiTransparent={!showNoteCardPrompt.answered} {...showNoteCardPrompt} onClose={() => setShowNoteCardPrompt(null)} /> : ""}
            <ContentArea>
                <ActionBanner html={(
                    <div>I pray for my <span className="text-theme-color">{randomPeoplePerson}</span>, that...</div>
                )} />
                {
                    error ?
                        <TryAgainView tryAgain={() => { getPinnedPeople(); getPeople(); }} />
                        :

                        (
                            <div className="flex flex-col">
                                {pinnedPeople?.length > 0 && <div className="mb-8 flex flex-col">
                                    <MiniInfo icon={<BsPinAngleFill size={"18"} />} text="Pinned" />
                                    <LayoutGroup layoutScroll shouldMeasureScroll>
                                        {
                                            pinnedPeople.map((peop, i) => {
                                                const iconBadges = [
                                                    (peop.pinned ? <BsPinAngleFill key="pin" /> : null)
                                                ]
                                                const altDragIcons = { right: false, left: peop.pinned }
                                                return (
                                                    <PeopleCard onPrayerClick={handleOnPrayerClick} onPrayerPin={onPrayerPin} onPrayerAnswered={onPrayerAnswered} expanded={focusedPerson === peop.id} loadError={(prayerError == peop.id)} getPeoplePrayers={getPeoplePrayers} prayerObject={prayerObject} dragOptions={PeopleCardDragOptions} altDragIcons={altDragIcons} iconBadges={iconBadges} key={i} {...peop} onClick={onFocus} onAdd={showAddPrayer} onEdit={handleEditPersonClick} onDelete={onPeopleDelete} />
                                                )
                                            })
                                        }
                                    </LayoutGroup>

                                </div>}
                                <div>
                                    {people?.length > 0 && <div className="mb-8 flex flex-col">
                                        <MiniInfo icon={<BsPeopleFill size={"18"} />} text="People" />
                                        <LayoutGroup layoutScroll shouldMeasureScroll>
                                            {
                                                people.map((peop, i) => {
                                                    const iconBadges = [
                                                        (peop.pinned ? <BsPinAngleFill key="pin" /> : null)
                                                    ]
                                                    const altDragIcons = { right: false, left: peop.pinned }
                                                    return (
                                                        <PeopleCard onPrayerClick={handleOnPrayerClick} onPrayerPin={onPrayerPin} onPrayerAnswered={onPrayerAnswered} expanded={focusedPerson === peop.id} loadError={(prayerError == peop.id)} getPeoplePrayers={getPeoplePrayers} prayerObject={prayerObject} dragOptions={PeopleCardDragOptions} altDragIcons={altDragIcons} iconBadges={iconBadges} key={i} {...peop} onClick={onFocus} onAdd={showAddPrayer} onEdit={handleEditPersonClick} onDelete={onPeopleDelete} />
                                                    )
                                                })
                                            }
                                        </LayoutGroup>
                                    </div>}
                                </div>

                            </div>
                        )
                }

            </ContentArea>

            <div className="absolute right-4 bottom-20" onClick={handleAddPersonClick}>
                <PlusButton />
            </div>

        </div>
    );
}





function MiniButton({ icon, onClick = () => { } }) {
    return (
        <div onClick={onClick} className="clickable p-1.5 rounded-full shadow bg-theme-mode-primary flex items-center justify-center">
            {icon}
        </div>
    )
}


{/* <div className="absolute right-4 bottom-20">
                <div className="h-12 p-1 mb-8 flex flex-col justify-center items-center w-full">
                    <AnimatePresence
                        initial={false}
                        exitBeforeEnter={true}
                    >
                        {openAddMenu && <motion.div
                            variants={miniButtonAnimation}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                        >
                            <MiniButton icon={<BsFillPersonFill size={"20"} />} onClick={handleAddPersonClick} />
                            <div className="py-2" />
                            <MiniButton icon={<FaPrayingHands size={"20"} />} />
                        </motion.div>}
                    </AnimatePresence>




                </div>
                <div className={"" + (openAddMenu ? "twist-45 " : "untwist-45 ")}>
                    <PlusButton onClick={() => setOpenAddMenu(() => !openAddMenu)} />
                </div>
            </div> */}

export default PeopleView;