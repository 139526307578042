import { useState, useEffect, useContext } from "react";

import { supabase } from "../client";


// Components 
import ActionBanner from "../components/banners/ActionBanner";
import ContentArea from '../components/ContentArea';
import TopBar from '../components/nav/TopBar';
import { PlusButton } from "../components/Buttons";
import NoteCard from "../components/cards/NoteCard";
import MiniInfo from "../components/badge/MiniInfo"

//Views
import AddPrayerView from "./Prayer/AddPrayerView";
import EditPrayerView from "./Prayer/EditPrayerView";

import { PageContext } from '../components/PageContextProvider';
import LoadingView from "./LoadingView";
import TryAgainView from "./TryAgainView";

// Icons 
import { FaTrash, FaDove, FaStar, FaRegStar, FaPray } from "react-icons/fa"
import { BsPinAngleFill, BsPinAngle } from "react-icons/bs";
import TailSpin from "react-loading-icons/dist/esm/components/tail-spin";
import NoteCardPrompt from "../components/cards/NoteCardPrompt";
import { AnimateSharedLayout, LayoutGroup } from "framer-motion";
import PrayersCol from "./Prayer/PrayersCol";






function PrayerView({ }) {

    const { user, setPopUp, setPrompt, theme } = useContext(PageContext)

    const [prayers, setPrayers] = useState([]);
    const [pinnedPrayers, setPinnedPrayers] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [showNoteCardPrompt, setShowNoteCardPrompt] = useState(false);








    useEffect(() => {

        // supabase
        //     .channel(`public:p_prayer:user_id=eq.'${user.id}'`)
        //     .on('postgres_changes', { event: '*', schema: 'public', table: 'p_prayer' }, payload => {
        //         getPinnedPrayers();
        //         getPrayers();
        //     })
        //     .subscribe()



        getPinnedPrayers();
        getPrayers();


    }, [])




    async function getPinnedPrayers() {
        setError(null);
        let { data, error } = await supabase
            .from('p_prayer')
            .select('*,id::text,user_id::text')
            .eq('user_id', user.id)
            .eq("pinned", true)
            .order("updated_at", { ascending: false })


        if (error) setError(error);
        else updatePinnedPrayerState(data, pinnedPrayers)

        setLoading(false)




    }

    async function getPrayers() {
        setError(null);
        let { data, error } = await supabase
            .from('p_prayer')
            .select('*,id::text,user_id::text')
            .eq("pinned", false)
            .order("updated_at", { ascending: false })


        if (error) setError(error);
        else updatePrayerState(data, prayers)

        setLoading(false)




    }

    function updatePinnedPrayerState(data, pinnedPrayers) {
        data = data.map(pra => {
            if (pinnedPrayers.filter(e => e.id === pra.id)[0]) pra.noAnimate = true;
            else pra.noAnimate = false;
            return pra;
        })
        setPinnedPrayers(data);
    }


    function updatePrayerState(data, prayers) {
        data = data.map(pra => {
            if (prayers.filter(e => e.id === pra.id)[0]) pra.noAnimate = true;
            else pra.noAnimate = false;
            return pra;
        })
        setPrayers(data);
    }


    function handleOnClick(id) {
        const data = getDataFromId(id);
        setShowNoteCardPrompt(data)
    }

    function handleOpenEdit(id) {
        const data = getDataFromId(id);
        setPopUp({ title: "Edit", element: <EditPrayerView data={data} onEdit={onEdit} /> })
        setShowNoteCardPrompt(null);
    }
    function handleOpenAdd(id) {
        const data = getDataFromId(id);
        setPopUp({ title: "ADD", element: <AddPrayerView onAdd={onAdd} /> })
    }


    function getDataFromId(id) {
        return prayers.filter(e => e.id === id)[0] || pinnedPrayers.filter(e => e.id === id)[0] || null;
    }



    function onAdd() {
        setPopUp(false);
        getPinnedPrayers();
        getPrayers();
    }

    function onEdit() {
        setPopUp(false);
        getPinnedPrayers();
        getPrayers();
    }

    function onDelete(id) {
        const data = getDataFromId(id);
        const value = (
            <div className="flex justify-start items-center">
                <FaTrash size="20" color="#f73e3e" /> <div className="ml-2">Delete <span className="font-semibold">{data.title}</span>?</div>
            </div>
        )

        setShowNoteCardPrompt(null);

        setPrompt({ value, onApprove: () => deletePrayer(id), buttonValues: { approve: "Delete" } });


        async function deletePrayer(id) {
            const { data, error } = await supabase
                .from('p_prayer')
                .delete()
                .eq('id', id)

            getPinnedPrayers();
            getPrayers();
        }
    }


    async function onAnswered(id) {
        const data = getDataFromId(id);
        data.answered = !data.answered;
        const newPrayers = prayers.map(pra => {
            if (pra.id === data.id) return data;
            return pra;
        })
        updatePrayerState(newPrayers, prayers);

        const { error } = await supabase
            .from('p_prayer')
            .update({ answered: data.answered })
            .eq('id', id)

        getPinnedPrayers();
        getPrayers();
    }


    async function onPin(id) {
        const data = getDataFromId(id);
        data.pinned = !data.pinned;
        const newPrayers = prayers.map(pra => {
            if (pra.id === data.id) return data;
            return pra;
        })
        updatePrayerState(newPrayers, prayers);

        const { error } = await supabase
            .from('p_prayer')
            .update({ pinned: data.pinned })
            .eq('id', id)

        getPinnedPrayers();
        getPrayers();

    }




    return (
        <div>
            <TopBar avatar wordmark="PRAYER PROJECT" clickables={(loading ? [<TailSpin height="20" strokeWidth="4" stroke={theme ? theme.color.hex : "#000"} />] : [])}>

            </TopBar>
            {showNoteCardPrompt ? <NoteCardPrompt onEdit={handleOpenEdit} onDelete={onDelete} onPin={onPin} pinnable semiTransparent={!showNoteCardPrompt.answered} {...showNoteCardPrompt} onClose={() => setShowNoteCardPrompt(null)} /> : ""}
            <ContentArea>
                <ActionBanner title="I pray that..." />

                {
                    error ?
                        <TryAgainView tryAgain={() => getPrayers()} />
                        :

                        (
                            <PrayersCol {...{ prayers, pinnedPrayers, onAnswered, onPin }} onClick={handleOnClick} />
                        )
                }
            </ContentArea>
            <div className="absolute right-4 bottom-20" onClick={() => handleOpenAdd()}>
                <PlusButton />
            </div>

        </div>
    );
}







export default PrayerView;