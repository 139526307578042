import React, { useContext } from 'react';
import { PageContext } from '../PageContextProvider';

function NavBarIcon({ children, selected, navigateTo }) {
    const {navigate} = useContext(PageContext);
    const handleClick = () => {
        navigate(navigateTo, { replace: true });
    }

    return (
        <div
            onClick={() => handleClick()}
            className={`clickable h-11 w-full rounded-lg p-2 mx-1 flex flex-row justify-center items-center hover:bg-theme-color-02 transition-all ${selected ? "bg-theme-color-02" : ""}`}>
            {children}
        </div>
    );
}

export default NavBarIcon;