import { supabase } from "./client";

const db = {
    getProfile: async function (options = {}) {
        try {
            var re = {}
            let column = "";
            let eq = "";
            if (options.user_id) {
                column = "user_id";
                eq = options.user_id;
            } else if (options.username) {
                column = "username";
                eq = options.username;
            }
    
            if (options.user_id || options.username) {
                let res = await supabase
                    .from('profiles')
                    .select("*")
                    // Filters
                    .eq(column, eq)
                
                re = res;
            }
            return re;
        } catch (err) {
            return {}
        }
        
    },
    upsertProfile: async function (values = {}) {
        const res = await supabase
            .from('profiles')
            .insert([values], { upsert: true })
        return res;
        //{ data: profiles, error }
    }
}




export default db;