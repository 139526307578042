import { useContext } from 'react';


import { PageContext } from '../../components/PageContextProvider';


// functions
import { getThemeData } from '../../config/themes';
import getTransparentColor from "../../functions/getTransparentColor"

//icons 
import { BsCheck, BsCheck2, BsCheckLg, BsClock, BsFillPersonPlusFill, BsFillPersonXFill, BsX, BsXLg } from "react-icons/bs";


export default function FriendCard({ user_id, name, username, mutual, p_theme, onRemove, onAdd, requested, onAccept, onIgnore }) { // mutual = mutual friends

    const { theme } = useContext(PageContext)

    const user_theme = getThemeData(p_theme?.color, theme.mode.name)


    const semiTransparentColor = getTransparentColor(user_theme.color.hex);



    return (
        <div style={{ color: user_theme?.color.hex }} className=' py-2.5 w-full flex justify-between items-center'>
            <div className='clickable flex justify-start items-center'>
                <div style={{ backgroundColor: user_theme?.color.hex }} className={`rounded-full flex justify-center items-center w-14 h-14`}>
                    <div className={`bg-theme-mode-background rounded-full w-12 h-12`}>
                        <div style={{ backgroundColor: semiTransparentColor }} className='w-full h-full rounded-full flex justify-center items-center'>
                            <p className='font-semibold text-lg'>{username ? username[0].toUpperCase() : "#"}</p>
                        </div>
                    </div>
                </div>
                <div className='pl-2 flex flex-col justify-start items-start'>
                    <p className='font-bold'>
                        {name}
                    </p>
                    <p style={{ color: theme?.mode.uicolor.hex }} className='-mt-2 opacity-60'>
                        @{username}
                    </p>
                    {mutual &&<p style={{ color: theme?.mode.uicolor.hex }} className='-mt-2 opacity-40'>
                        {mutual} mutual friends
                    </p>}
                </div>
            </div>
            <div>
                {onRemove && <FriendButton onClick={() => onRemove({ id: user_id, username })}>
                    <BsX size={"28"} color={theme?.mode.uicolor.hex} />
                </FriendButton>}
                {requested && <FriendButton>
                    <BsClock size={"19"} color={theme?.mode.uicolor.hex} />
                </FriendButton>}
                {onAdd && <FriendButton onClick={() => onAdd({ id: user_id, username })}>
                    <BsFillPersonPlusFill size={"21"} color={theme?.mode.uicolor.hex} />
                </FriendButton>}
                {onAccept && onIgnore && <div className='flex w-fit'>
                    <FriendButton onClick={() => onAccept({ id: user_id, username })}>
                        <BsCheck2 size={"22"} color={theme?.mode.uicolor.hex} />
                    </FriendButton>
                    <FriendButton onClick={() => onIgnore({ id: user_id, username })}>
                        <BsX size={"28"} color={theme?.mode.uicolor.hex} />
                    </FriendButton>
                </div>}
            </div>
        </div>
    )


    function FriendButton({ onClick = () => { }, children }) {
        return (
            <div onClick={onClick} className='rounded-md clickable flex justify-center items-center p-2 w-fit '>
                {children}
            </div>
        )
    }
}