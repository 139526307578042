import React, { useContext } from 'react';

import { TailSpin } from 'react-loading-icons';
import { TertiaryButton } from '../components/Buttons';

import { PageContext } from '../components/PageContextProvider';

function LoadingView({ signOut }) {

    const { theme } = useContext(PageContext) || {}

    return (
        <div className='h-full w-full flex flex-col justify-center items-center'>
            <TailSpin height="40" strokeWidth="2" stroke={theme ? theme.color.hex : "#000"} />
            {signOut && <div className='mt-4'><TertiaryButton onClick={signOut || (() => {}) }>Sign Out?</TertiaryButton></div>}
        </div>
    );
}

export default LoadingView;