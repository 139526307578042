import { useState, useEffect, useContext } from "react";

import { supabase } from "../client";

import { PageContext } from '../components/PageContextProvider';

// Components 
import ActionBanner from "../components/banners/ActionBanner";
import ContentArea from '../components/ContentArea';
import TopBar from '../components/nav/TopBar';
import { PlusButton } from "../components/Buttons";
import NoteCard from "../components/cards/NoteCard";
import NoteCardPrompt from "../components/cards/NoteCardPrompt";
import MiniInfo from "../components/badge/MiniInfo";

//Views
import AddGratitudeView from "./Gratitude/AddGratitudeView";
import EditGratitudeView from "./Gratitude/EditGratitudeView";
import LoadingView from "./LoadingView";
import TryAgainView from "./TryAgainView";

// Icons 
import { FaTrash, FaStar, FaRegStar, FaHeart } from "react-icons/fa"
import TailSpin from "react-loading-icons/dist/esm/components/tail-spin";






function GratitudeView({ }) {

    const { user, setPopUp, setPrompt, theme } = useContext(PageContext)

    const [gratitudes, setGratitudes] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);



    const [showNoteCardPrompt, setShowNoteCardPrompt] = useState(false);


    const GratitudeCardDragOptions = {
        left: {
            icon: (<FaTrash size="18" color="#f73e3e" />),
            onDragged: onDelete
        },
        right: {
            icon: (<FaStar size="23" color="#eec300" />),
            altIcon: (<FaRegStar size="23" color={theme.mode.text.hex} />),
            onDragged: onStar
        },
    }





    useEffect(() => {

        // supabase
        //     .channel(`public:p_gratitude:user_id=eq.'${user.id}'`)
        //     .on('postgres_changes', { event: '*', schema: 'public', table: 'p_gratitude' }, payload => {
        //         getGratitudes();
        //     })
        //     .subscribe()



        getGratitudes();


    }, [])




    async function getGratitudes() {
        setError(null);
        let { data, error } = await supabase
            .from('p_gratitude')
            .select('*,id::text,user_id::text')
            .eq('user_id', user.id)
            .order("updated_at", { ascending: false })


        if (error) setError(error);
        else updateGratitudeState(data, gratitudes)

        setLoading(false)




    }


    function updateGratitudeState(data, gratitudes) {
        data = data.map(gra => {
            if (getDataFromId(gra.id)) gra.noAnimate = true;
            else gra.noAnimate = false;
            return gra;
        })
        setGratitudes(data);
    }


    function handleOnClick(id) {
        const data = getDataFromId(id);
        setShowNoteCardPrompt(data)
    }

    function handleOpenEdit(id) {
        const data = getDataFromId(id);
        setPopUp({ title: "Edit", element: <EditGratitudeView data={data} onEdit={onEdit} /> })
        setShowNoteCardPrompt(null);
    }
    function handleOpenAdd(id) {
        const data = getDataFromId(id);
        setPopUp({ title: "ADD", element: <AddGratitudeView onAdd={onAdd} /> })
        setShowNoteCardPrompt(null);
    }


    function getDataFromId(id) {
        return gratitudes.filter(e => e.id === id)[0] || null;
    }



    function onAdd() {
        setPopUp(false);
        getGratitudes();
    }

    function onEdit() {
        setPopUp(false);
        getGratitudes();
    }

    function onDelete(id) {
        setShowNoteCardPrompt(null);
        const data = getDataFromId(id);
        const value = (
            <div className="flex justify-start items-center">
                <FaTrash size="20" color="#f73e3e" /> <div className="ml-2">Delete <span className="font-semibold">{data.title}</span>?</div>
            </div>
        )

        setPrompt({ value, onApprove: () => deleteGratitude(id), buttonValues: { approve: "Delete" } });


        async function deleteGratitude(id) {
            const { data, error } = await supabase
                .from('p_gratitude')
                .delete()
                .eq('id', id)

            getGratitudes();
        }
    }


    async function onStar(id) {
        const data = getDataFromId(id);
        data.starred = !data.starred;
        const newGratitudes = gratitudes.map(gra => {
            if (gra.id === data.id) return data;
            return gra;
        })
        updateGratitudeState(newGratitudes, gratitudes);

        const { error } = await supabase
            .from('p_gratitude')
            .update({ starred: data.starred })
            .eq('id', id)

        getGratitudes();

    }




    return (
        <div>
            <TopBar avatar wordmark="PRAYER PROJECT" clickables={(loading ? [<TailSpin height="20" strokeWidth="4" stroke={theme ? theme.color.hex : "#000"} />] : [])}>

            </TopBar>
            {showNoteCardPrompt ? <NoteCardPrompt onEdit={handleOpenEdit} onDelete={onDelete} onStar={onStar} starrable {...showNoteCardPrompt} onClose={() => setShowNoteCardPrompt(null)} /> : ""}
            <ContentArea>
                <ActionBanner title="I'm thankful for..." />
                {gratitudes?.length > 0 && <MiniInfo icon={<FaHeart size="18" />} text="Gratitude" />}
                {
                    error ?
                        <TryAgainView tryAgain={() => getGratitudes()} />
                        :
                        (gratitudes.map(thankyou => {
                            const iconBadges = [
                                (thankyou.starred ? <FaStar key="star" size="22" /> : null)
                            ]
                            const altDragIcons = { right: thankyou.starred }


                            return (<NoteCard readMore={true} iconBadges={iconBadges} altDragIcons={altDragIcons} noAnimate={thankyou.noAnimate} dragOptions={GratitudeCardDragOptions} key={thankyou.id} onClick={handleOnClick} {...thankyou} />)
                        }))
                }
            </ContentArea>
            <div className="absolute right-4 bottom-20" onClick={() => handleOpenAdd()}>
                <PlusButton />
            </div>

        </div>
    );
}

export default GratitudeView;