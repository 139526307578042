import React, { useState, useEffect } from 'react';

import { motion, AnimatePresence } from "framer-motion"

import TopBar from '../components/nav/TopBar';

import { Button, SecondaryButton, TertiaryButton } from "../components/Buttons.jsx";
import Form, { FormRow } from "../components/form/Form"
import LargeTextInput from '../components/form/LargeTextInput';

import { BsGoogle, BsFacebook } from "react-icons/bs"
import { MdAlternateEmail } from "react-icons/md"
import { CgClose } from "react-icons/cg"
import { RiKeyFill } from "react-icons/ri"
import ContentArea from '../components/ContentArea';







const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-z]+)$/;
const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/



const LoginButton = ({ children, name, icon, onClick }) => {
    return (
        <button onClick={onClick} className='px-3 py-2 rounded-xl text-base font-bold text-theme-color my-5 w-full bg-theme-color-02 flex flex-row justify-start items-center border-4 border-theme-color'>

            {icon &&
                <div className='mr-4'>
                    {icon}
                </div>}

            {name}

            {children}
        </button>
    );
}

function LoginView({ supabase, setUser, setPrompt, resetTheme }) {




    const [emailInput, setEmailInput] = useState("");
    const [invalid, setInvalid] = useState();
    const [invalidMessage, setInvalidMessage] = useState("");
    const [showError, setShowError] = useState("");

    useEffect(() => {
        resetTheme(null)
    }, [])

    useEffect(() => {

        // validating email
        let invalid = false;
        let str = "";

        const emailValid = emailRegex.test(emailInput);
        if (emailInput.length <= 0) invalid = true;
        if (!emailValid && emailInput.length > 0) {
            invalid = true;
            str = "Email invalid";
        }

        setInvalid(invalid);
        setInvalidMessage(str);

    }, [emailInput])

    async function loginWithGoogle() {
        setShowError("");
        const { user, session, error } = await supabase.auth.signInWithOAuth(
            { provider: 'google', options: { redirectTo: window.location.origin } },
        )
        if (error) setShowError("Error trying to sign in with Google");
        else if (user) setUser(user);

    }


    async function loginWithEmail() {
        setShowError("");
        const { user, session, error } = await supabase.auth.signInWithOtp(
            { email: emailInput, options: { redirectTo: window.location.origin } },

        )
        if (error && error.status == 429) setShowError("Try again in 60 Seconds");
        else if (error) setShowError("Error trying to sign up with email");
        else if (user) setUser(user);
        else setPrompt({ value: "Email Sent!", buttonValues: { cancel: " ", approve: "Okay" } })

    }





    return (
        <div className='absolute z-40 top-0 bottom-0 right-0 left-0 bg-theme-mode-background overflow-scroll scrollbar-hide flex flex-row justify-center'>
            <ContentArea>
                <div className='font-bold text-3xl text-center py-3 px-2 text-theme-color font-wordmark drop-shadow-theme-color-02 drop-shadow-[1px_1px_1px]'>
                    PRAYER PROJECT
                </div>
                <div>
                    <LoginButton name="Google" icon={<BsGoogle size="20" />} onClick={() => loginWithGoogle()} />
                </div>
                {true && <div className='mt-8 pt-3 border-t-2 border-theme-color'>
                    <Form error={showError} invalid={invalid} invalidMessage={invalidMessage} buttonText="Send Email" onSubmit={() => loginWithEmail()}>
                        <FormRow>
                            <LargeTextInput icon={<MdAlternateEmail size="20" />} type="email" placeholder='Email' onChange={email => setEmailInput(email.trim())} />
                        </FormRow>
                    </Form>

                </div>}

            </ContentArea>
        </div>
    );
}




export default LoginView;
